import React, { useState, useEffect } from "react"
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../Api/comman";
import moment from "moment";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import config from "../../config/config";
import { CSVLink } from 'react-csv'
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  Table,
  Button, Link,
  CircularProgress,
  IconButton,
  Icon
} from '@mui/material';
import isEmpty from "is-empty";

const columns = [
  // { field: 'id', headerName: 'ID', width: 30 },
  {
    field: 'createdAt',
    headerName: 'Date',
    width: 100,
    valueGetter: (params) => moment(params.row.createdAt).format('L'),
  },
  {
    field: 'time',
    headerName: 'Time',
    width: 100,
    valueGetter: (params) => moment(params.row.createdAt).format('LTS'),
  },
  {
    field: 'address',
    headerName: 'Address',
    width: 250,
  },
  {
    field: 'payment_id',
    headerName: 'TransactionId',
    width: 250,
  },
  {
    field: 'userId',
    headerName: 'UserId',
    width: 210,
  },
  {
    field: 'transaction_hash',
    headerName: 'TransactionHash',
    width: 250,
    renderCell: (params) => { return (<Link href={`${config.TRXURL}${params.row.transaction_hash}`} target="_blank">{params.row.transaction_hash}</Link>) },
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
  },
  {
    field: 'prebalance',
    headerName: 'Before Balance',
    width: 140,
  },
  {
    field: 'tokens',
    headerName: 'Token',
    width: 80,
  },
  {
    field: 'aftbalance',
    headerName: 'After Balance',
    width: 140,
  },
  {
    field: 'payment_method',
    headerName: 'PaymentMethod',
    width: 140,
  },
  {
    field: 'status',
    headerName: 'status',
    width: 80,
  },
];
const DepositTable = (() => {
  const [Records, setRecords] = useState([]);
  const [RecordsRange, setRecordsRange] = useState();
  const user = useSelector((state) => state.user)
  const [csvdata, setcsvdata] = React.useState([]);
  const getData = async (data) => {
    let reqdata = { id: data.id, type: data.type, from: data.from ? data.from : '', to: data.to ? data.to : '' }
    let { success, result } = await getRecords(reqdata);
    if (success) {
      let respData = result.map((item, key) => {
        return {
          id: key,
          ...item,
        };
      });
      console.log(respData, "result-------12")
      setRecords(respData);
      setcsvdata(respData)
    }
  };

  useEffect(() => {
    if (user.IsLogin) {
      let data = { id: user.userId, type: "Deposit" };
      getData(data);
    }
  }, [user])

  const handleRangeFrom = ((value) => {
    if (value) {
      let rangedata = { ...RecordsRange, ...{ ['from']: value } }
      setRecordsRange(rangedata);
    }
  })

  const handleRangeTo = ((value) => {
    if (value) {
      let rangedata = { ...RecordsRange, ...{ ['to']: value } }
      setRecordsRange(rangedata);
    }
  })


  const handleReport = (() => {
    if(isEmpty(RecordsRange)){
      return
    }else{
      let data = { id: user.userId, type: "Deposit", from: RecordsRange.from, to: RecordsRange.to };
      getData(data);
    }
   
  })
  const exportPDF = async () => {
    const unit = "pt";
    const size = "A2"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const imageData = config.logoImg;
    const title = "Deposit Report";

    const headers = [["Date", "Time", "Address", "TransactionId", "TransactionHash", 'Email', 'Before balance', 'Tokens', 'After balance', "PaymentMethod", 'status']];

    const data = csvdata.map(elt => [moment(elt.createdAt).format('L'), moment(elt.createdAt).format('LTS'), elt.address, elt.payment_id, elt.transaction_hash, elt.email, elt.prebalance, elt.tokens, elt.aftbalance, elt.payment_method, elt.status]);

    let content = {
      startY: 110,
      head: headers,
      body: data
    };
    doc.addImage(imageData, 'JPG', 871, 20, 200, 70)
    var str = "Copyright © Infiniteway, All rights Reserved";
    var pageSize = doc.internal.pageSize;
    var pageHeight = pageSize.height
      ? pageSize.height
      : pageSize.getHeight();
    doc.text(str, marginLeft, pageHeight - 40);
    doc.text(title, marginLeft, 68);
    doc.autoTable(content);
    doc.save("Deposit Report.pdf")
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  return (
    <>
      <div className="report_filter">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker label="From" onChange={handleRangeFrom} />
          <DatePicker label="To" onChange={handleRangeTo} />
          {/* <DateRangePicker onChange={handleRange} localeText={{ start: 'From', end: 'To' }} /> */}
        </LocalizationProvider>
        <Button onClick={handleReport}>get Report</Button>
      </div>
      <CSVLink
        data={csvdata}
        filename={"DepositReport.csv"}
        style={{ textDecoration: 'none' }}
      >
        <Button> DOWNLOAD CSV</Button>
      </CSVLink>
      <IconButton onClick={exportPDF}>
        <Button> DOWNLOAD PDF</Button>
      </IconButton>
      <Box sx={{ height: 300, width: '100%' }}>
        {Records.length > 0 && <DataGrid
          rows={Records}
          columns={columns}
          // slots={{
          //   toolbar: CustomToolbar,
          // }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
        />}
      </Box>
    </>
  )
})

export default DepositTable;