import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom";

// @material-ui/core components
import Header from "../components/Header/Header.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import { makeStyles } from "@material-ui/core/styles";

// core components

import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";

import styles from "../assets/jss/material-kit-react/views/home";
import { loginValidation } from "../Validation/depositValidation.js";
import {Login_User} from "../Api/comman";
import { addUser, removeUser } from '../redux-store/reducer'
import { toastAlert } from "../helper/toastAlert.js";
import isEmpty from "../helper/isEmpty.js";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {setAuthorization} from "../comman/axios.config.js"


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);
const dashboardRoutes = [];
var initdata = {email:"",password: "",hash:""};
export default function Login(props) {
  const { ...rest } = props;
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");
  const type = searchParams.get("type");
  const hash = searchParams.get("hash");

console.log(hash,'hash444')
  const Navigate = useNavigate()
  const dispatch = useDispatch()
  const [logindata,setlogindata]= useState(initdata)
  const [disable,setdisable]= useState(false)
  const [Redirect,setRedirect]= useState("/home")
  const [validation,setvalidation] = useState({})
  const handleChange = ((e) => {
    let { id, value } = e.target;
    let Logindata = { ...logindata, ...{ [id]: value } }
    setlogindata(Logindata);
  })

  const handleSubmit=(async()=>{
    console.log(logindata,"logindata")
    let valid = await loginValidation(logindata);
    if(!isEmpty(valid)){
      setvalidation(valid);
      if(hash){
        localStorage.setItem('hash',`email=${email}&hash=${hash}&type=${type}`);
        localStorage.setItem('email',email);
        localStorage.setItem('weuueiq',hash);
      }
      console.log(valid,"valid")
      return false;
    }
    var {message,success,errors,result,user,data} = await Login_User(logindata);
    if(success){
      console.log(user,'result')
    localStorage.setItem("authtoken",result)
  
    setAuthorization(result)
 
    if(data){
      dispatch(addUser({
        IsLogin:true,
        userId:user.id,
        email:user.email,
        amount:data.amount,
        website:data.website,
        balance: user.tokens,
        url:data.url,
        item:data.item,
        type:user.type
      }))
    localStorage.setItem("amount",data.amount);
    localStorage.setItem('weuueiq',hash);
    }else{
      dispatch(addUser({
        IsLogin:true,
        userId:user.id,
        email:user.email,
        balance: user.tokens,
        type:user.type
      })) 
    } 
    localStorage.removeItem("wallettype");
    localStorage.removeItem("account");
    toastAlert("success","Login successfully");
    console.log(Redirect,"redirect")
    if(data){
      Navigate('/receipt', {
        state: {
          payamount: data.amount,
        }
      });
    }else{
    Navigate(Redirect);
    }
    }else{
      setvalidation(errors)
      toastAlert("error",message);
      if(hash){
        localStorage.setItem('hash',`email=${email}&hash=${hash}&type=${type}`);
        localStorage.setItem('email',email);
        localStorage.setItem('weuueiq',hash);
        
      }
    }
    if(user && user.type && user.type == 'partner' && !hash){
      localStorage.setItem("partnersite", user.sitename)
      Navigate('/partner-dashboard');
      
    }
  })


  useEffect(()=>{
    if(email){
      if(hash){
        let formdata = {...logindata,...{email:email,hash:hash}}
      setlogindata(formdata);
      localStorage.setItem('hash',`email=${email}&hash=${hash}&type=${type}`);
        localStorage.setItem('email',email);
        localStorage.setItem('weuueiq',hash);
      }else{
      let formdata = {...logindata,...{email:email}}
      setlogindata(formdata);
      }
      setdisable(true);
      if(type){
        setRedirect(type);
      }
    }
  },[])

  console.log("paramsdata",email);
  return (
    <div>
      <ScrollToTopOnMount />
      <div className="page-header-buy">
        <div className={classes.container}>
          <GridContainer className="align-items-center">
            <GridItem md={12} lg={6} className="mx-auto">
              <div className="buy_box">
              
                <div className="buy_box_inner">
                <div className="brand_logo_div img-fluid mb-4"><img src={require("../assets/images/logo_01.png")} alt="logo" /></div>
                  <h4>Login</h4>
                  <div class="form-group">
                    <label for="amount">Email Id</label>
                    <input type="text" class="form-control" id="email" disabled={disable} onChange={handleChange} value={logindata.email} />
                  </div>
                  {validation.email&&<span style={{color:"red"}}>{validation.email}</span>}
                  <div class="form-group">
                    <label for="token">Password</label>
                    <input type="password" class="form-control" id="password" onChange={handleChange} placeholder="Password" value={logindata.password} />
                  </div>
                  {validation.password&&<span style={{color:"red"}}>{validation.password}</span>}
                  <div className="text-center mt-4">
                    <button className="primary_btn" onClick={handleSubmit}>Login</button>
                    <small><Link to="/forgot-password">Forgot password</Link></small>
                  </div>
                  <div className="text-center mt-4">
                  <small>New user Register here,<Link to="/Register">Register</Link></small>
                  </div>
                  <div className="text-center mt-4">
                  <small>New partner Register here,<Link to="/partner-register">partner Register</Link></small>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>

    </div>
  );
}
