import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";
import {
  Link,
  useNavigate,
  useParams,
  useLocation,
  Navigate,
} from 'react-router-dom'
// core components
import Header from "../components/Header/Header.js";
import FooterHome from "../components/Footer/FooterHome.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import styles from "../assets/jss/material-kit-react/views/home.js";
import {EmailDetails} from "../Api/comman"
import moment from "moment";
import Wallet_Popup from "../Model/wallet_popup.jsx";
import { depositvalidation } from "../Validation/depositValidation.js";
import isEmpty from "../helper/isEmpty.js";
import INFINITYABI from "../ABI/infinity.json";
import Web3 from "web3";
import config from "../config/config.js";
import { toastAlert } from "../helper/toastAlert.js";
import { getRecords } from "../Api/comman.js";
import { useDispatch, useSelector } from "react-redux";

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);

// const columns = [
//   { field: "id", headerName: "S.No" },
//   {
//     field: "date",
//     headerName: "Date",    
//     editable: true,
//   },
//   {
//     field: "currency",
//     headerName: "Currency",    
//     editable: true,
//   },
//   {
//     field: "item",
//     headerName: "Item",
//     editable: true,
//   },
//   {
//     field: "prevbalance",
//     headerName: "Previous Balance",
//     editable: true,
//   },
//   {
//     field: "amount",
//     headerName: "Amount",
//     editable: true,
//   },
//   {
//     field: "afterbalance",
//     headerName: "After Balance",
//     editable: true,
//   },
//   {
//     field: "paymethod",
//     headerName: "Payment Method",
//     editable: false,
//   },   
//   {
//     field: "paytype",
//     headerName: "Payment Type",
//     editable: false,
//   },  
//   {
//     field: "status",
//     headerName: "Status",
//     editable: false,
//   }, 
// ];

// const rows = [
//   { id: 1, date:"03/29/2023", currency: "INR", item: "", prevbalance: "35", amount:  "10", afterbalance: '25', paymethod: "Card", paytype: 'Buy',status: "Success"  },
//   { id: 2, date:"03/29/2023", currency: "INR", item: "", prevbalance: "35", amount:  "10", afterbalance: '25', paymethod: "Card", paytype: 'Buy',status: "Success"  },
//   { id: 3, date:"03/29/2023", currency: "INR", item: "", prevbalance: "35", amount:  "10", afterbalance: '25', paymethod: "Card", paytype: 'Buy',status: "Success"  },
//   { id: 4, date:"03/29/2023", currency: "INR", item: "", prevbalance: "35", amount:  "10", afterbalance: '25', paymethod: "Card", paytype: 'Buy',status: "Success"  },
//   { id: 5, date:"03/29/2023", currency: "INR", item: "", prevbalance: "35", amount:  "10", afterbalance: '25', paymethod: "Card", paytype: 'Buy',status: "Success"  }, 
// ];

export default function TransactionReport(props) {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [address, setaddress] = useState();
  const [token, settoken] = useState();
  const [Records, setRecords] = useState([])
  const { userId } = useParams()
  const { ...rest } = props;
  const columns = [
    // { field: 'id', headerName: 'ID', width: 30 },
    {
        field: 'createdAt',
        headerName: 'Date',
        width: 200,
        valueGetter: (params) => moment(params.row.createdAt).format('L'),
        headerAlign: 'center',
        align: 'center',
    },
    // {
    //     field: 'time',
    //     headerName: 'Time',
    //     width: 100,
    //     valueGetter: (params) => moment(params.row.createdAt).format('LTS'),
    //     headerAlign: 'center',
    //     align: 'center',
    // },
    // {
    //     field: 'payment_id',
    //     headerName: 'TransactionId',
    //     // type: 'number',
    //     width: 250,
    //     // editable: true,
    //     headerAlign: 'center',
    //     align: 'center',
    // },
    // {
    //     field: 'userId',
    //     headerName: 'UserId',
    //     // type: 'number',
    //     width: 210,
    //     // editable: true,
    //     headerAlign: 'center',
    //     align: 'center',
    // },
    // {
    //     field: 'email',
    //     headerName: 'Email',
    //     // type: 'number',
    //     width: 250,
    //     // editable: true,
    //     headerAlign: 'center',
    //     align: 'center',
    // },
    {
        field: 'currency',
        headerName: 'currency',
        // type: 'number',
        width: 200,
        // editable: true,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'item',
        headerName: 'Item',
        // type: 'number',
        width: 200,
        // editable: true,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'prebalance',
        headerName: 'Previous Balance',
        // type: 'number',
        width: 210,
        // editable: true,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'tokens',
        headerName: 'Amount',
        // type: 'number',
        width: 210,
        // editable: true,
        renderCell: (params) => (
            <div style={{ color: 'green' }}>{params.row.tokens}</div>
        ),
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'aftbalance',
        headerName: 'After Balance',
        // type: 'number',
        width: 210,
        // editable: true,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'payment_method',
        headerName: 'PaymentMethod',
        // type: 'number',
        width: 200,
        // editable: true,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'payment_type',
        headerName: 'Payment Type',
        // type: 'number',
        width: 200,
        // editable: true,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'status',
        headerName: 'status',
        // type: 'number',
        width: 200,
        // editable: true,
        headerAlign: 'center',
        align: 'center',
    },
]
  const viewReport = async () => {
 

    let { status, result, message } = await EmailDetails(userId)
    if (status) {
        let respData = await result.map((item, key) => {
            return {
                id: key,
                ...item,
            }
        })
        setRecords(respData)
    
    }
    console.log('INVIEW: ', userId)
}
  useEffect(() => {
    viewReport()
    console.log(user, "redux-----12");
  }, [user]);
  return (
    <div>
      <ScrollToTopOnMount />
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo" />}
        rightLinks={
          <HeaderLinks
            address={address}
            settoken={settoken}
            token={token}
            setaddress={setaddress}
          />
        }
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
      <div className="page-header" name="home">
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={12} lg={12} className="m-auto">
              <div className="buy_box_inner">
                <h2>Transaction Report</h2>
                {/* <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  checkboxSelection
                  disableSelectionOnClick
                /> */}
                 {Records.length > 0 && (
                            <DataGrid
                                rows={Records}
                                columns={columns}
                                // slots={{
                                //     toolbar: CustomToolbar,
                                // }}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                checkboxSelection
                                disableRowSelectionOnClick
                            />
                        )}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
