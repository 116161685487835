import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from "react-router-dom";
// @material-ui/core components
import Header from "../components/Header/Header.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import { makeStyles } from "@material-ui/core/styles";

// core components

import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";

import styles from "../assets/jss/material-kit-react/views/home";
import { changepasswordValidation } from "../Validation/depositValidation.js";
import {Resetpassword,checktoken} from "../Api/comman";
import { addUser, removeUser } from '../redux-store/reducer'
import { toastAlert } from "../helper/toastAlert.js";
import isEmpty from "../helper/isEmpty.js";
import { useNavigate } from "react-router-dom";



// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);
const dashboardRoutes = [];
var initdata = {
    "password": "",
    "confirmpassword": "",
  
  }
export default function ResetPassword(props) {
  const { ...rest } = props;
  const classes = useStyles();
  const Navigate = useNavigate()
  const { authToken } = useParams();
  console.log(authToken,'authToken')
  const [logindata,setlogindata]= useState(initdata)
  const [validation,setvalidation] = useState({})
  const handleChange = ((e) => {
    let { id, value } = e.target;
    let Logindata = { ...logindata, ...{ [id]: value } }
    setlogindata(Logindata);
  })

  const handleSubmit=(async()=>{
    console.log(logindata,"logindata")
    let valid = await changepasswordValidation(logindata);
    if(!isEmpty(valid)){
      setvalidation(valid);
      console.log(valid,"valid")
      return false;
    }
    var data = {
        "password":logindata.password,
        "confirmpassword":logindata.confirmpassword,
        'passwordtoken':authToken
    }
    let {message,success,errors,result,user} = await Resetpassword(data);
    if(success){
    
  
    toastAlert("success",message);
    Navigate('/login')
    }else{
      toastAlert("error",message);
    }

  })
  const checkToken=(async()=>{
      let data={
          'passwordtoken':authToken
      }
    let {message,success,errors,result,user} = await checktoken(data);
    if(success==false){
        toastAlert("error",message);
        Navigate('/home')
    }
  })
  useEffect(() => {
    checkToken()
    
  }, []);
  return (
    <div>
      <ScrollToTopOnMount />
      {/* <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo"/>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      /> */}
      <div className="page-header-buy">
        <div className={classes.container}>
          <GridContainer className="align-items-center">
            <GridItem md={12} lg={6} className="mx-auto">
              <div className="buy_box">
                <div className="buy_box_inner">
                  <div className="brand_logo_div img-fluid mb-4"><img src={require("../assets/images/logo_01.png")} alt="logo" /></div>
                  <h4>Reset Password</h4>
                  <div class="form-group">
                    <label for="token">Password</label>
                    <input type="password" class="form-control" id="password" onChange={handleChange} value={logindata.password} placeholder="Password" />
                  </div>
                  {validation.password && <span style={{color:"red"}}>{validation.password}</span>}

                  <div class="form-group">
                    <label for="token">Confirm Password</label>
                    <input type="password" class="form-control" id="confirmpassword" onChange={handleChange} value={logindata.confirmpassword} placeholder="Confirm Password" />
                  </div>
                  {validation.confirmpassword && <span  style={{color:"red"}}>{validation.confirmpassword}</span>}
                  <div className="text-center mt-4">
                    <button className="primary_btn" onClick={handleSubmit}>Submit</button>
                   
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>

    </div>
  );
}
