import axios from "axios"
import config from "../config/config"

import { getAuthToken } from '../helper/localstorage'

axios.defaults.baseURL = config.baseurl;
axios.defaults.headers.common['Authorization'] = getAuthToken();

export const setAuthorization = (token) => {
    console.log(token,"token")
    axios.defaults.headers.common['Authorization'] = token;
}

export const removeAuthorization = () => {
    delete axios.defaults.headers.common["Authorization"];
}

export default axios;