import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Spinner, Alert } from "react-bootstrap"
// core components
import Header from "../components/Header/Header.js";
import FooterHome from "../components/Footer/FooterHome.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import styles from "../assets/jss/material-kit-react/views/home.js";
import { validuser, sendEmailOTP, withdraw_Token, getipdetails, getservicefee } from "../Api/comman"
import { Link } from "react-router-dom";
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { toastAlert } from "../helper/toastAlert.js";
import isEmpty from "../helper/isEmpty.js";
import { withdrawvalidation } from "../Validation/withdrawValidation.js";

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);



export default function Withdraw(props) {
  const location = useLocation();
  const navigate = useNavigate()
  const user = useSelector((state) => state.user)
  // const { address } = location.state;
  const classes = useStyles();
  const [count, setCount] = useState(1);
  const [expanded, setExpanded] = React.useState("panel1");
  const [verify, setverify] = useState(true);
  const [otpstatus, setotpstatus] = useState();
  const [OTP, setOTP] = useState();
  const [loaderdata, setloaderdata] = useState()
  const [tokens, settokens] = useState();
  const [token, settoken] = useState();
  const [address, setaddress] = useState();
  const [validation, setvalidation] = useState({});
  const [loading, setloading] = useState();
  const [errormessage, seterrormessage] = useState()
  const [flatefee,setflatefee] = useState(0)
  const [percentagefee,setpercentagefee] = useState(0)
  const [servicefee,setservicefee] = useState(0)





  let initdata = {
    "address": "",
    "amount": "",
    "email": "",
    "verifyemail": "",
    "otp": "",
  };
  const [withdrawdata, setwithdrawdata] = useState(initdata);

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setCount(1);
    if (user.address) {
      setaddress(user.address)
      let data = { ...withdrawdata, ...{ address: user.address,email:user.email } }
      setwithdrawdata(data)
      fetchservicefee();
    }
  }, [count, user.address]);

  const fetchservicefee=(async()=>{
    let { message, success, result } = await getservicefee();
    console.log(result,"result---123")
    if(success){
    setflatefee(result.flatfee)
    setpercentagefee(result.percentagefee)
    }
  })

  const { ...rest } = props;

  const handlechange = (async (e) => {
    let { id, value } = e.target;
    setwithdrawdata({ ...withdrawdata, ...{ [id]: value } });
    if (id === "amount") {
      if (tokens < value) {
        let errors = { amount: "you don't have enough tokens" }
        setvalidation(errors);
      } else {
        setvalidation({});
      }
      let percentagefeevalue = (percentagefee /100) * value;
      if(flatefee > percentagefeevalue || flatefee == percentagefeevalue){
        setservicefee(flatefee);
      }else{
        setservicefee(percentagefeevalue);
      }
      console.log(percentagefeevalue,"value");
    }

  })

  const sendOTP = (async () => {
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

    if (isEmpty(withdrawdata.email)) {
      toastAlert("error", "please Enter Email");
      return false;
    } else if (!(emailRegex.test(withdrawdata.email))) {
      toastAlert("error", "Please enter Valid email");
      return false;
    }
    setotpstatus(true);
    let reqdata = {
      email: withdrawdata.email,
      identifier: "withdraw_OTP"
    }
    let { message, success } = await sendEmailOTP(reqdata);
    if (success) {
      toastAlert("success", "OTP sended")
    }
  })

  const verifyOTP = (() => {
    setverify(false);
  })

  const verifyUser = (async () => {
    if (tokens > 0) {
      toastAlert("error", "Email Already verified");
      return false;
    }
    if (!withdrawdata.email) {
      toastAlert("error", "please Enter Email");
      return false;
    }
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

    if (isEmpty(withdrawdata.email)) {
      toastAlert("error", "please Enter Email");
      return false;
    } else if (!(emailRegex.test(withdrawdata.email))) {
      toastAlert("error", "Please enter Valid email");
      return false;
    }
    setloaderdata("verifyuser");
    let reqdata = { email: withdrawdata.email, identifier: "withdraw_OTP" }
    let { success, result, message } = await validuser(reqdata);
    if (success) {
      console.log(result, "result")
      toastAlert("success", 'OTP sent to mail');
      settokens(result.tokens);
      setotpstatus(true);
    } else {
      seterrormessage(message)
      toastAlert("error", message);
    }
    setloaderdata("");
  })


  const withdrawToken = (async () => {

    setloading(true);
    let { ip, city, region, country_name } = await getipdetails();
    let systemdetails = {
      ip: ip,
      city: city,
      state: region,
      country: country_name,
    }
    let userdata = {
      address: address,
      tokens: withdrawdata.amount,
      email: withdrawdata.email,
      code: withdrawdata.otp,
      servicefee: servicefee,
      wallettype: localStorage.getItem("wagmi.wallet")
    }
    let reqdata = { user: userdata, info: systemdetails }

    let errors = await withdrawvalidation(reqdata);
    console.log(errors, "eeeeeee")
    if (!isEmpty(errors)) {
      setvalidation(errors);
      return false;
    }
    let { message, success } = await withdraw_Token(reqdata)
    if (success) {
      toastAlert("success", message);
      setotpstatus(false);
      setloading(false)
      navigate('/home')
    } else {
      toastAlert("error", message);
      setloading(false)
    }
  })
  return (
    <div>
      <ScrollToTopOnMount />
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo" />}
        rightLinks={<HeaderLinks address={address} settoken={settoken} token={token} setaddress={setaddress} />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
      <div className="page-header" name="home">
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={12} lg={8} className="m-auto">
              <div className="buy_box_inner">
                <h2>Withdraw</h2>
                <div class="form-group">
                  <label for="amount">Wallet Address</label>
                  <input
                    type="text"
                    readOnly
                    class="form-control"
                    value={withdrawdata.address}
                    id="address"
                    placeholder="Enter Address"
                  />
                  {validation.address && <span>{validation.address}</span>}
                </div>
                <div class="form-group">
                  <label for="token">Enter your Registered Email</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      id="email"
                      value={withdrawdata.email}
                      onChange={handlechange}
                      disabled={user.email?true:false}
                      // readOnly={tokens > 0 ? true : false}
                      class="form-control"
                      aria-describedby="basic-addon2"
                    />
                    <div class="input-group-append">
                      <button class="input-group-text" disabled={loaderdata === "verifyuser" ? true : false} onClick={verifyUser} id="basic-addon2">
                        {loaderdata === "verifyuser" ? <Spinner animation="border" size="sm" /> : "Verify User"}
                      </button>
                    </div>
                  </div>
                </div>
                {tokens > 0 &&
                 <div class="form-group">
                  <label for="amount">Enter Amount of INW</label>
                  <input
                    type="number"
                    class="form-control"
                    value={withdrawdata.amount}
                    onChange={handlechange}
                    id="amount"
                    placeholder="Enter INW Amount"
                  />
                </div>
                } 
                {errormessage && <Alert variant={"danger"}> {errormessage}</Alert>}
                {validation.amount && <Alert variant={"danger"}> {validation.amount}</Alert>}

                {tokens > 0 && 
                <div class="form-group">
                  <label for="amount">Enter OTP</label>
                  <input
                    type="text"
                    class="form-control"
                    value={withdrawdata.otp}
                    onChange={handlechange}
                    id="otp"
                    placeholder="Enter OTP"
                  />
                  {validation.otp && <Alert variant={"danger"}> {validation.otp}</Alert>}
                </div>
                }
                {tokens && <h2>Available Tokens: {tokens} INW</h2>}
                {servicefee && <h2>service Fee: {servicefee} INW</h2>}
                {servicefee && withdrawdata && withdrawdata.amount && <h2>you will Receive: {withdrawdata.amount - servicefee >0?(withdrawdata.amount - servicefee).toFixed(2):0} INW</h2>}
                {loading ?
                  <div className="text-center mt-4">
                    <button className="primary_btn">
                      <Spinner animation="grow" size="sm" /><Spinner animation="grow" size="sm" /><Spinner animation="grow" size="sm" />
                    </button>
                  </div> : user && isEmpty(user.address) ?
                    <div className="text-center mt-4">
                      <button className="primary_btn" data-toggle="modal" data-target="#exampleModal">Connect Wallet</button>
                      <small>First connect your wallet</small>
                    </div> :
                    <div className="text-center mt-4">
                      <button className="primary_btn" disabled={otpstatus ? false : true} onClick={withdrawToken}>Withdraw Token</button>
                      {otpstatus ? <small>To check your Mail</small> : <small>No extra fees</small>}
                    </div>
                }
                {/* <div className="text-center mt-4">
                    <button className="primary_btn" onClick={withdrawToken}>Withdraw</button>
                    <small>No extra fees</small>
                  </div> */}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
