import React, { useEffect, useState } from "react";
import { useNavigate,Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Header from "../components/Header/Header.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
// core components

import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import { OTPValidation, RegisterValidation } from "../Validation/depositValidation";
import { Regiseter_User } from "../Api/comman";
import { toastAlert } from "../helper/toastAlert.js";


import styles from "../assets/jss/material-kit-react/views/home";
import isEmpty from "../helper/isEmpty.js";


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);
const dashboardRoutes = [];
var initRegdata = {
  "email": "",
  "password": "",
  "otp": "",
  "confirmpassword": "",
  "status": "user",
}
export default function Register(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const Navigate = useNavigate();
  const [otpstatus, setotpstatus] = useState(false);
  const [emailstatus, setemailstatus] = useState(false);
  const [Registerdata, setRegisterdata] = useState(initRegdata)
  const [validation, setvalidation] = useState({})

  useEffect(()=>{
    
    if(localStorage.getItem('email')){
      let email = localStorage.getItem('email');
      let formdata = {...Registerdata,...{['email']:email}}
      setRegisterdata(formdata)
      setemailstatus(true);
    }
  },[])

  const handleChange = ((e) => {
    let { id, value } = e.target;
    let regiseterdata = { ...Registerdata, ...{ [id]: value } }
    setRegisterdata(regiseterdata);
  })

  const handleSubmit = (async () => {
    let valid = await RegisterValidation(Registerdata);
    if (!isEmpty(valid)) {
      setvalidation(valid)
      return false;
    }
    console.log(Registerdata, "Regiseterdata.....")
    let { errors, message, success } = await Regiseter_User(Registerdata);
    if (success) {
    toastAlert("success","Register successfully !!!");
    localStorage.removeItem('email');
    var hash = localStorage.getItem('hash');
    if(hash){
      Navigate(`/login?${hash}`)
    }else{
      Navigate("/login")
    }
    } else {
      setvalidation(errors);
    }
  })

  const handleOtp = (async () => {
    let valid = await OTPValidation(Registerdata);
    if (!isEmpty(valid)) {
      setvalidation(valid)
      return false;
    }
    let { errors, message, success } = await Regiseter_User(Registerdata);
    if (success) {
      setotpstatus(true)
      toastAlert("success",message);
      setemailstatus(true);
    } else {
      setvalidation(errors)
    }
  })
  return (
    <div>
      <ScrollToTopOnMount />
      <div className="page-header-buy">
        <div className={classes.container}>
          <GridContainer className="align-items-center">
            <GridItem md={12} lg={6} className="mx-auto">
              <div className="buy_box">
                <div className="buy_box_inner">
                <div className="brand_logo_div img-fluid mb-4"><img src={require("../assets/images/logo_01.png")} alt="logo" /></div>
                  <h4>Register</h4>
                  <div class="form-group">
                    <label for="amount">Email Id</label>
                    <div className="input-group">
                      <input type="text" class="form-control" id="email" onChange={handleChange} value={Registerdata.email} disabled={emailstatus} />
                      <div class="input-group-append">
                        <button className="primary_btn" onClick={handleOtp}>Send OTP</button>
                      </div>
                    </div>
                    {validation.email && <span style={{color:"red"}}>{validation.email}</span>}
                  </div>
                  {otpstatus && <div class="form-group">
                    <label for="amount">Enter OTP</label>
                    <input type="text" class="form-control" id="otp" onChange={handleChange} value={Registerdata.otp} placeholder="Enter the OTP" />
                  </div>
                  }
                  {validation.otp && <span style={{color:"red"}}>{validation.otp}</span>}
                  <div class="form-group">
                    <label for="token">Password</label>
                    <input type="password" class="form-control" id="password" onChange={handleChange} value={Registerdata.password} placeholder="Password" />
                  </div>
                  {validation.password && <span style={{color:"red"}}>{validation.password}</span>}

                  <div class="form-group">
                    <label for="token">Confirm Password</label>
                    <input type="password" class="form-control" id="confirmpassword" onChange={handleChange} value={Registerdata.confirmpassword} placeholder="Confirm Password" />
                  </div>
                  {validation.confirmpassword && <span style={{color:"red"}}>{validation.confirmpassword}</span>}
                  <div className="text-center mt-4">
                    <button className="primary_btn" onClick={handleSubmit} disabled={!otpstatus}>Register</button>
                    <small>Already a member? <Link to="/login">Login</Link></small>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>

    </div>
  );
}