import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    IsLogin: false,
    userId: '',
    email: '',
    address: '',
    balance: '',
    token: '',
    website: '',
    type:'',
  },
  reducers: {
    addUser: (state,action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

     return state= {...state,...action.payload}
    },
    removeUser: (state) => {
     return state = {
     IsLogin: false,
     userId: '',
     email: '',
     amount: '',
     address: '',
     token:'',
     balance: '',
     website: '',
     url: '',
     item: '',
   }
    },
  },
})

// Action creators are generated for each case reducer function
export const { addUser, removeUser, incrementByAmount } = userSlice.actions

export default userSlice.reducer