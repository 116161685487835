// import lib
import isEmpty from "../helper/isEmpty";
import Web3 from "web3";

export const withdrawvalidation = async(value) => {
  let {user} = value;
  let errors = {};
  let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
  console.log(user,"value")
  if (isEmpty(user.email)) {
    errors.email = "Please enter your email";
  }else if(!(emailRegex.test(user.email))){
    errors.email = "Please enter Valid email";
  }
  if (isEmpty(user.tokens)) {
    errors.amount = "Please enter your token amount";
  }
  if (isEmpty(user.address)) {
    errors.address = "Please enter your wallet address";
  } else if (!Web3.utils.isAddress(user.address)) {
    errors.address = "Please enter valid wallet address";
  }
  if (isEmpty(user.code)) {
    errors.otp = "Please enter your OTP";
  } 
  return errors;
};

