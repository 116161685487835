import React,{ useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ConditionRoute=(({children:children, type: type})=>{
    // const Navigate = useNavigate();
  const user = useSelector((state) => state.user)

    useEffect(()=>{
        
    },[])
    console.log(type,user,"Type---123");
    if (type === 'private' && !localStorage.getItem("authtoken")) {
        return( <Navigate to="/login" />)
      }else{
      return children;
      }
})

export default ConditionRoute;
