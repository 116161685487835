import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "../components/Header/Header.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import styles from "../assets/jss/material-kit-react/views/home.js";
import moment from "moment";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@mui/material'
import { useDispatch, useSelector } from "react-redux";
import Chart from "react-apexcharts";
import{PartnerDepositChart,partnerDetails,PartnerBuyChart,PartnerWithdrawChart,PartnerPaymentChart} from '../Api/comman'
const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);
const initdata = {
  email: "",
  address: "",
  amount: "",
};


  const options = {
    xaxis: {
      categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
    }
  };
  const series = [
    {
      name: "Deposit",
      data: [30, 40, 25, 50, 49, 21, 70, 51]
    },   
  ];

export default function PartnerDashboard(props) {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [address, setaddress] = useState();
  const [token, settoken] = useState();
  const [users, setusers]= useState(1);
  const { ...rest } = props;
  const [deposit, setDeposit] = useState([])
  const [value, setValue] = useState('Daily')
  const [depositOptions, setDepositOptions] = useState([])
  const [depositSeries, setDepositSeries] = useState([])
  const [buyOptions, setbuyOptions] = useState([])
  const [buySeries, setbuySeries] = useState([])
  const [withdrawOptions, setwithdrawOptions] = useState([])
  const [withdrawSeries, setwithdrawSeries] = useState([])
  const depositoptions = {
    xaxis: {
      categories: depositOptions
    }
  };
  const depositseries = [
    {
      name: "Deposit",
      data: depositSeries
    },   
  ];
  const buyoptions = {
    xaxis: {
      categories: buyOptions
    }
  };
  const buyseries = [
    {
      name: "BuyTokens",
      data: buySeries
    },   
  ];
  const withdrawoptions = {
    xaxis: {
      categories: withdrawOptions
    }
  };
  const withdrawseries = [
    {
      name: "Received",
      data: withdrawSeries
    },   
  ];
  const getdetails=async()=>{
    let data = localStorage.getItem("partnersite")
    console.log(data,'data54')
    var datas = {sitename:data}
    let{status,message,totalUsers}  = await partnerDetails(datas);
  
    setusers(totalUsers? totalUsers:0);
 }

useEffect(() => {
    getdetails()
}, [])

const handleChange = (event) => {
  setValue(event.target.value)
}
  const depositDetails = async () => {
        try {
            let sitename = localStorage.getItem('partnersite')
            let data = {
                sitename: sitename,
                type: value
            }
            const { result } = await PartnerDepositChart(data)
            setDeposit(result)
            console.log(result,'result90')
            var arr1 = [];
            var arr2 = [];
            for (var i in result){
              arr1.push(result[i]._id)
              arr2.push(result[i].depositTokens)
            }
            console.log(arr1,'arr1',arr2)
            setDepositOptions(arr1)
            setDepositSeries(arr2)
        }
        catch (err) { }
    }

    const buyDetails = async () => {
      try {
          let sitename = localStorage.getItem('partnersite')
          let data = {
              sitename: sitename,
              type: value
          }
          const { result } = await PartnerBuyChart(data);
        console.log(result,'reeeeeeeeee')
        var arr3 = [];
        var arr4 = [];
        for (var i in result){
          arr3.push(result[i]._id)
          arr4.push(result[i].buyTokens)
        }
        setbuyOptions(arr3)
        setbuySeries(arr4)
      }
      catch (err) {}
  }

  Date.prototype.getWeek = function() {
    var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
  }
  
  function getDateRangeOfWeek(weekNo, y){
      var d1, numOfdaysPastSinceLastMonday, rangeIsFrom, rangeIsTo;
      d1 = new Date(''+y+'');
      numOfdaysPastSinceLastMonday = d1.getDay() - 1;
      d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
      d1.setDate(d1.getDate() + (7 * (weekNo - d1.getWeek())));
      rangeIsFrom = (d1.getMonth() + 1) + "-" + d1.getDate() + "-" + d1.getFullYear();
      d1.setDate(d1.getDate() + 6);
      rangeIsTo = (d1.getMonth() + 1) + "-" + d1.getDate() + "-" + d1.getFullYear() ;
      return rangeIsFrom + " to " + rangeIsTo;
  };


  const paymentDetails = async () => {
    try {
        let sitename = localStorage.getItem('partnersite')
        let data = {
            sitename: sitename,
            type: value
        }
        const { result } = await PartnerPaymentChart(data);
        var arr5 = [];
        var arr6 = [];
        for (var i in result){
          console.log(result[i]._id,'result[i]._id')
          if(value=='Weekly'){
            var range = getDateRangeOfWeek(result[i]._id,new Date().getFullYear())
         
            arr5.push(range)
            arr6.push(result[i].withdrawTokens)
          }
          else{
            arr5.push(result[i]._id)
            arr6.push(result[i].withdrawTokens)
          }
         
        }
        setwithdrawOptions(arr5)
        setwithdrawSeries(arr6)
    }
    catch (err) {}
}
    useEffect(() => {
        depositDetails()
        buyDetails()
        paymentDetails()
    }, [value])
  useEffect(() => {
    console.log(user, "redux-----12");
  }, [user]);

  return (
    <div>
      <ScrollToTopOnMount />
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo" />}
        rightLinks={
          <HeaderLinks
            address={address}
            settoken={settoken}
            token={token}
            setaddress={setaddress}
          />
        }
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
      <div className="page-header" name="home">
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={12} lg={12} className="m-auto">
              <div className="buy_box_inner">
                <h2>Partner Dashboard</h2>
                <GridContainer>
                  <GridItem md={6} lg={4}>
                    <div className="partner_dash_box">
                      <div>
                        <img src={require("../assets/images/user_icon.png")} alt="" className="img-fluid" />
                        <div className="partner_dash_box_content">
                          <h2>Total Transactions</h2>
                          <h3>{users}</h3>
                        </div>
                      </div>
                   <a href="/partner-site-report"> <i className="bi bi-arrow-right-circle-fill"></i></a>
                    </div>
                  </GridItem>
                </GridContainer>
                {/* <div className="dash_graph_wrap">
                  <h3>Deposit Graph</h3>
                  <Chart options={depositoptions} series={depositseries} type="line" className="dash_line_chart" />
                </div>

                <div className="dash_graph_wrap">
                  <h3>Buy Graph</h3>
                  <Chart options={buyoptions} series={buyseries} type="line" className="dash_line_chart" />
                </div> */}

                <div className="dash_graph_wrap">
                  <h3>Payment Graph</h3>
                  <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        label="values"
                        onChange={handleChange}
                    >
                        <MenuItem value="Daily">Daily</MenuItem>
                        <MenuItem value="Weekly">Weekly</MenuItem>
                        <MenuItem value="Monthly">Monthly</MenuItem>
                        <MenuItem value="Yearly">Yearly</MenuItem>
                    </Select>
                  <Chart options={withdrawoptions} series={withdrawseries} type="line" className="dash_line_chart" />
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
