import React, { useState, useEffect } from "react"
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../Api/comman";
import moment from "moment";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv'
import jsPDF from "jspdf";
import "jspdf-autotable";
import isEmpty from 'is-empty'
import {
  Table,
  Button,
  CircularProgress,
  IconButton,
  Icon
} from '@mui/material';
import config from '../../config/config'


var columns=[]
const PaymentTable = (() => {
  const [Records, setRecords] = useState([]);
  const [RecordsRange, setRecordsRange] = useState();

  const user = useSelector((state) => state.user)
  const [csvdata, setcsvdata] = React.useState([]);
  // const [value, setValue] = React.useState(dayjs('2023-04-17'));
if(user&&user.type == 'partner'){
  var columns = [
    // { field: 'id', headerName: 'ID', width: 30 },
    {
      field: 'createdAt',
      headerName: 'Date',
      width: 100,
      valueGetter: (params) => moment(params.row.createdAt).format('L'),
    },
    {
      field: 'time',
      headerName: 'Time',
      width: 100,
      valueGetter: (params) => moment(params.row.createdAt).format('LTS'),
    },
    {
      field: 'payment_id',
      headerName: 'TransactionId',
      // type: 'number',
      width: 250,
      // editable: true,
    },
    {
      field: 'useremail',
      headerName: 'user Email',
      width: 210,
      valueGetter: (params) => {
        if (params.row.email == params.row.useremail) {
          return '-';
        }
        else{
          return params.row.useremail
        }
       
      },
    },
    // {
    //   field: 'email',
    //   headerName: 'Email',
    //   width: 250,
    // },
    {
      field: 'website',
      headerName: 'Site',
      width: 110,
    },
    {
      field: 'item',
      headerName: 'Item',
      width: 110,
    },
    {
      field: 'prebalance',
      headerName: 'Before balance',
      width: 80,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 80,
    },
    {
      field: 'aftbalance',
      headerName: 'After balance',
      width: 80,
    },
    // {
    //   field: 'payment_method',
    //   headerName: 'PaymentMethod',
    //   width: 100,
    // },
    {
      field: 'status',
      headerName: 'status',
      width: 80,
    },
  ];
}
if(user&&user.type == 'user'){
  var columns = [
    // { field: 'id', headerName: 'ID', width: 30 },
    {
      field: 'createdAt',
      headerName: 'Date',
      width: 100,
      valueGetter: (params) => moment(params.row.createdAt).format('L'),
    },
    {
      field: 'time',
      headerName: 'Time',
      width: 100,
      valueGetter: (params) => moment(params.row.createdAt).format('LTS'),
    },
    {
      field: 'payment_id',
      headerName: 'TransactionId',
      // type: 'number',
      width: 250,
      // editable: true,
    },
    // {
    //   field: 'useremail',
    //   headerName: 'user mail',
    //   width: 210,
    // },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
    },
    {
      field: 'website',
      headerName: 'Site',
      width: 110,
    },
    {
      field: 'item',
      headerName: 'Item',
      width: 110,
    },
    {
      field: 'prebalance',
      headerName: 'Before balance',
      width: 80,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 80,
    },
    {
      field: 'aftbalance',
      headerName: 'After balance',
      width: 80,
    },
    // {
    //   field: 'payment_method',
    //   headerName: 'PaymentMethod',
    //   width: 100,
    // },
    {
      field: 'status',
      headerName: 'status',
      width: 80,
    },
  ];
}
  const getData = async (data) => {
    let reqdata = { id: data.id, type: data.type, from: data.from ? data.from : '', to: data.to ? data.to : '' }
    let { success, result } = await getRecords(reqdata);
    console.log('succesdddult : ', success, result);
    if (success) {
      let respData = result.map((item, key) => {
        return {
          id: key,
          ...item,
        };
      });
      console.log(respData, "result-------12")
      setRecords(respData);
      setcsvdata(respData)
    }
  };
  const exportPDF = async () => {
    const unit = "pt";
    const size = "A2"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const imageData = config.logoImg;
    const title = "Payment Report";

    const headers = [["Date", "Time", "TransactionId", "UserId", 'Email', 'website', 'Item', 'Before balance', 'Amount', 'After balance', 'status']];

    const data = csvdata.map(elt => [moment(elt.createdAt).format('L'), moment(elt.createdAt).format('LTS'), elt.payment_id, elt.userId, elt.email, elt.website, elt.item, elt.prebalance, elt.amount, elt.aftbalance, elt.status]);

    let content = {
      startY: 110,
      head: headers,
      body: data
    };
    doc.addImage(imageData, 'JPG', 871, 20, 200, 70)
    var str = "Copyright © Infiniteway, All rights Reserved";
    var pageSize = doc.internal.pageSize;
    var pageHeight = pageSize.height
      ? pageSize.height
      : pageSize.getHeight();
    // doc.line(100, 100, 660, 30);
    doc.text(str, marginLeft, pageHeight - 40);
    doc.text(title, marginLeft, 68);
    doc.autoTable(content);
    doc.save("Payment Report.pdf")
  }
  useEffect(() => {
    console.log(user, "redux-----12")
    if (user.IsLogin) {
      let data = { id: user.userId, type: "Payment" };
      getData(data);
    }
  }, [user])

  const handleRangeFrom = ((value) => {
    if (value) {
      let rangedata = { ...RecordsRange, ...{ ['from']: value } }
      setRecordsRange(rangedata);
    }

  })

  const handleRangeTo = ((value) => {
    if (value) {
      let rangedata = { ...RecordsRange, ...{ ['to']: value } }
      setRecordsRange(rangedata);
    }
  })

  const handleReport = (() => {
    if (isEmpty(RecordsRange)) {
      return
    }
    else {
      let data = { id: user.userId, type: "Payment", from: RecordsRange.from, to: RecordsRange.to };
      getData(data);
    }
  })

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  return (
    <>
      <div className="report_filter">
        <LocalizationProvider dateAdapter={AdapterDayjs} >
          <DatePicker label="From" onChange={handleRangeFrom} />
          <DatePicker label="To" onChange={handleRangeTo} />
        </LocalizationProvider>
        <Button onClick={handleReport}>get Report</Button>
      </div>
      <CSVLink
        data={csvdata}
        filename={"paymentReport.csv"}
        style={{ textDecoration: 'none' }}
      >
        <Button> DOWNLOAD CSV</Button>
      </CSVLink>
      <IconButton onClick={exportPDF}>
        <Button> DOWNLOAD PDF</Button>
      </IconButton>
      <Box sx={{ height: 300, width: '100%' }}>
        {Records.length > 0 && <DataGrid
          rows={Records}
          columns={columns}
          // slots={{
          //   toolbar: CustomToolbar,
          // }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
        />}
      </Box>
    </>
  )
})

export default PaymentTable;