import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addUser, removeUser } from '../redux-store/reducer'

export default function Counter() {
  const count = useSelector((state) => state.user.value)
  const dispatch = useDispatch()
console.log(count,"count")
  return (
    <div>
      <div>
        <button
          aria-label="Increment value"
          onClick={() => dispatch(addUser({userId:"1111",email:"surendran@gmail.com",address:"0x3423423423423sdfs34234234234324"}))}
        >
          Increment
        </button>
        {count&&<span>{count.address}</span>}
        {count&&<span>{count.email}</span>}
        {count&&<span>{count.userId}</span>}
        <button
          aria-label="Decrement value"
          onClick={() => dispatch(removeUser())}
        >
          Decrement
        </button>
      </div>
    </div>
  )
}