import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'

// @material-ui/core components
import Header from "../components/Header/Header.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import { makeStyles } from "@material-ui/core/styles";

// core components

import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";

import styles from "../assets/jss/material-kit-react/views/home";
import { emailValidation } from "../Validation/depositValidation.js";
import {Forgotpassword} from "../Api/comman";
import { addUser, removeUser } from '../redux-store/reducer'
import { toastAlert } from "../helper/toastAlert.js";
import isEmpty from "../helper/isEmpty.js";
import { useNavigate } from "react-router-dom";



// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);
const dashboardRoutes = [];
var initdata = {email:""};
export default function ForgotPassword(props) {
  const { ...rest } = props;
  const classes = useStyles();
  const Navigate = useNavigate()
  const dispatch = useDispatch()
  const [logindata,setlogindata]= useState(initdata)
  const [validation,setvalidation] = useState({})
  const handleChange = ((e) => {
    let { id, value } = e.target;
    let Logindata = { ...logindata, ...{ [id]: value } }
    setlogindata(Logindata);
  })

  const handleSubmit=(async()=>{
    let valid = await emailValidation(logindata);
    if(!isEmpty(valid)){
      setvalidation(valid);
      
      return false;
    }
    
    let {message,success,error} = await Forgotpassword(logindata);
    console.log(success,'success57')
    if(success){
    toastAlert("success",message);
    Navigate('/')
    }else{
      toastAlert("error",message);
    }

  })
  return (
    <div>
      <ScrollToTopOnMount />
      {/* <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo"/>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      /> */}
      <div className="page-header-buy">
        <div className={classes.container}>
          <GridContainer className="align-items-center">
            <GridItem md={12} lg={6} className="mx-auto">
              <div className="buy_box">
                <div className="buy_box_inner">
                  <div className="brand_logo_div img-fluid mb-4"><img src={require("../assets/images/logo_01.png")} alt="logo" /></div>
                  <h4>Forgot Password</h4>
                  <div class="form-group">
                    <label for="amount">Email Id</label>
                    <input type="text" class="form-control" id="email" onChange={handleChange} value={logindata.email} />
                  </div>
                  {validation.email&&<span style={{color:"red"}}>{validation.email}</span>}
                  
            
                  <div className="text-center mt-4">
                    <button className="primary_btn" onClick={handleSubmit}>Submit</button>
                    
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>

    </div>
  );
}
