import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {Spinner,Alert} from "react-bootstrap"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "../components/Header/Header.js";
import FooterHome from "../components/Footer/FooterHome.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import styles from "../assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import Wallet_Popup from "../Model/wallet_popup.jsx";
import { depositvalidation } from "../Validation/depositValidation";
import isEmpty from "../helper/isEmpty.js";
import INFINITYABI from "../ABI/infinity.json";
import Web3 from "web3";
import config from "../config/config";
import { toastAlert } from "../helper/toastAlert.js";
import {getipdetails, updatedepositetoken } from "../Api/comman"
import {useDispatch,useSelector} from "react-redux"
import { addUser } from "../redux-store/reducer.js";
const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);
const initdata = {
  email: "",
  address: "",
  amount: "",
}
export default function Deposit(props) {
  const classes = useStyles();
  const user = useSelector((state)=>state.user);
  const dispatch = useDispatch()
  const Navigate = useNavigate()
  const [count, setCount] = useState(1);
  const [expanded, setExpanded] = React.useState("panel1");
  const [address, setaddress] = useState(user.address);
  const [depositdata, setdepositdata] = useState(initdata)
  const [token,settoken] = useState();
  const [depositerrors, setdepositerrors] = useState({});
  const [loading,setloading] = useState(false)



  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setCount(1);
    if(user.address){
      setaddress(user.address)
      settoken(user.token)
      let emaildata = {...depositdata,...{email:user.email}}
      setdepositdata(emaildata);
    }
  }, [count,user.address]);

  const { ...rest } = props;

  const handlechange = (async (e) => {
    let { id, value } = e.target;
    setdepositdata({ ...depositdata, ...{ [id]: value } });
    if (isEmpty(depositdata.address)) {
      setdepositdata({ ...depositdata, ...{ address: address } });
    }
  })

  const handlesubmit = (async () => {
    try{
    let valid =await depositvalidation(depositdata, token);
    console.log(valid, 'errors')
    if (!isEmpty(valid)) {
      setdepositerrors(valid);
      return false;
    }
    setloading(true)
    let web3= new Web3(window.ethereum);
    let tokencontract = new web3.eth.Contract(INFINITYABI, config.INFINITEWAY);
    let amountoftoken = (depositdata.amount * 1e18).toString();
    try{

      var getBalance = await web3.eth.getBalance(address);
      var bal = getBalance / 10 ** 18;
      var estimategas = await tokencontract.methods
      .transfer(config.ADMINADDRESS,amountoftoken)
      .estimateGas({ from: address });
    var gas = estimategas + 100000;
    gas = gas / 10 ** 8;
  
    if (gas > bal) {
      toastAlert("error", "Insufficient Gas fee", "invalid");
      setloading(false)
      return;
    }
      var gasPrice = await web3.eth.getGasPrice();
      console.log(estimategas,gasPrice,'gasPrice')
      var transaction = await tokencontract.methods.transfer(config.ADMINADDRESS,amountoftoken).send({from:address,gasLimit: estimategas, gasPrice: gasPrice })
    }catch(err){
      console.log(err,'erdeositer')
    toastAlert("error","Deposit Rejected!!!");
    setloading(false)
    return false;
    }
    console.log(transaction,"transaction---------123");
    let {ip,city,region,country_name} = await getipdetails();
    let info = {
      ip:ip,
      city: city,
      state: region,
      country: country_name
    }
    let wallettype = localStorage.getItem("wagmi.wallet");
    console.log(wallettype,'wallettype')
    let user = {email:depositdata.email,tokens:depositdata.amount,txid:transaction.transactionHash,address:address,paymentmethod:wallettype};
    let reqdata = {user: user,info: info};
    let {success,result}= await updatedepositetoken(reqdata);
    if(success){
    toastAlert("success","Token Deposit Successfully!!!");
    setloading(false)
    dispatch(addUser({
      balance: result
    }))
    Navigate("/home");
    }
  }catch(err){
    setloading(false)
    toastAlert("error","Deposit Failed!!!");

    console.log(err,"error");
  }
  })

  return (
    <div>
      <ScrollToTopOnMount />
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo"/>}
        rightLinks={<HeaderLinks  address={address} settoken={settoken}  token={token} setaddress={setaddress} />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
      
     
      <div className="page-header" name="home">
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={12} lg={8} className="m-auto">
              <div className="buy_box_inner">
                <h2>Deposit</h2>
                <div class="form-group">
                  <label for="amount">Enter your Email</label>
                  <input
                    type="email"
                    onChange={handlechange}
                    value={depositdata.email}
                    disabled={depositdata.email?true:false}
                    class="form-control"
                    id="email"
                    placeholder="Enter your email id"
                  />
                  {depositerrors.email && <Alert  variant={"danger"}>{depositerrors.email}</Alert>}
                </div>
                <div class="form-group">
                  <label for="amount">your wallet Address</label>
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    readOnly
                    value={address}
                    placeholder="Enter  wallet Address"
                  />
                  {depositerrors.address &&<Alert  variant={"danger"}>{depositerrors.address}</Alert>}
                </div>
                <div class="form-group">
                  <label for="amount">Enter INW Amount</label>
                  <input
                    type="number"
                    class="form-control"
                    id="amount"
                    value={depositdata.amount}
                    onChange={handlechange}
                    placeholder="Enter INW Amount"
                  />
                  {depositerrors.amount &&<Alert  variant={"danger"}>{depositerrors.amount}</Alert>}
                </div>
                {address && <h2>Your Token Balance: {token > 0 ? token : 0}</h2>}
                {address ?
                loading?<div className="text-center mt-4">
                <button className="primary_btn" ><Spinner animation="grow" size="sm" /><Spinner animation="grow" size="sm" /><Spinner animation="grow" size="sm" /></button>
                <small>Loading...</small>
              </div>:
                 <div className="text-center mt-4">
                  <button className="primary_btn" onClick={handlesubmit}>Deposit</button>
                  <small>No extra fees</small>
                </div> :
                  <div className="text-center mt-4">
                    <button className="primary_btn" data-toggle="modal" data-target="#exampleModal">Connect Wallet</button>
                    <small>First connect your wallet</small>
                  </div>}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
