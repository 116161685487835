import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import { DataGrid,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "../components/Header/Header.js";
import FooterHome from "../components/Footer/FooterHome.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import styles from "../assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import moment from "moment"
import Wallet_Popup from "../Model/wallet_popup.jsx";
import { depositvalidation } from "../Validation/depositValidation";
import isEmpty from "../helper/isEmpty.js";
import INFINITYABI from "../ABI/infinity.json";
import Web3 from "web3";
import config from "../config/config";
import { toastAlert } from "../helper/toastAlert.js";
import { getRecords } from "../Api/comman";
import {useDispatch,useSelector} from "react-redux";
import PaymentTable from "./Tables/Payment-table.jsx";
import DepositTable from "./Tables/Deposit-table.jsx";
import WithdrawTable from "./Tables/Withdraw-table.jsx";
import BuytokenTable from "./Tables/Buytoken-table.jsx";

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);
const initdata = {
  email: "",
  address: "",
  amount: "",
}

export default function Report(props) {
  const classes = useStyles();
  const user = useSelector((state) => state.user)
  const [address, setaddress] = useState()
  const [token, settoken] = useState()


  const { ...rest } = props;

// <<<<<<< HEAD

  useEffect(()=>{
    console.log(user,"redux-----12")

  },[user])

// =======
// <<<<<<< HEAD
// =======
//   const getData = async (data) => {

//     let reqdata = {id: data.id,type:data.type}
//     let { success, result } = await getRecords(reqdata);
//     if (success) {

//       let respData = result.map((item, key) => {
//         return {
//           id: key,
//           ...item,
//         };
//       });
//     console.log(respData,"result-------12")
//       setRecords(respData);
//     }
//   };

//   useEffect(()=>{
//     console.log(user,"redux-----12")
//     if(user.IsLogin){
//     let data={id:user.userId,type:"Buy"};
//     getData(data);
//     }
//   },[user])

//   const handleTable=((type)=>{
//     let data={id:user.userId,type:type};
//     getData(data);
//   })
// >>>>>>> 2fe3742 (Moved to server)

//   function CustomToolbar() {
//     return (
//       <GridToolbarContainer>
//         <GridToolbarExport />
//       </GridToolbarContainer>
//     );
//   }

// <<<<<<< HEAD
// =======
// // console.log(Records,"records");
// // >>>>>>> 59a7545 (Conflict fixed)
// >>>>>>> 2fe3742 (Moved to server)
  return (
    <div>
      <ScrollToTopOnMount />
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo" />}
        rightLinks={<HeaderLinks address={address} settoken={settoken}  token={token} setaddress={setaddress} />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
      <div className="page-header" name="home">
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={12} lg={12} className="m-auto">
              <div className="buy_box_inner">
                <h2>Reports</h2>
                <nav>
                  <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                    <a class="nav-item nav-link active" id="nav-transaction-tab" data-toggle="tab" href="#nav-transaction" role="tab" aria-controls="nav-transaction" aria-selected="true">Payment Report</a>
                    {user&&user.IsLogin && user.type != 'partner' &&
                    <>
                     <a class="nav-item nav-link" id="nav-payment-tab" data-toggle="tab" href="#nav-payment" role="tab" aria-controls="nav-payment" aria-selected="false">Buy Token Report</a>
                    <a class="nav-item nav-link" id="nav-deposit-tab" data-toggle="tab" href="#nav-deposit" role="tab" aria-controls="nav-deposit" aria-selected="false">Deposit Report </a>
                    </>
                    }
                    <a class="nav-item nav-link" id="nav-withdraw-tab" data-toggle="tab" href="#nav-withdraw" role="tab" aria-controls="nav-withdraw" aria-selected="false">Withdraw Report </a>
                  </div>
                </nav>
                <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                  <div class="tab-pane fade show active" id="nav-transaction" role="tabpanel" aria-labelledby="nav-transaction-tab">
                    <>
                    {user&&user.IsLogin&&<PaymentTable />}
                    </>
                  </div>
                  <div class="tab-pane fade" id="nav-payment" role="tabpanel" aria-labelledby="nav-payment-tab">
                  <>
                  {user&&user.IsLogin && user.type != 'partner' &&<BuytokenTable />}
                  </>
                  </div>
                  <div class="tab-pane fade" id="nav-deposit" role="tabpanel" aria-labelledby="nav-deposit-tab">
                  <>
                  {user&&user.IsLogin && user.type != 'partner' &&<DepositTable />}
                  </>
                  </div>
                  <div class="tab-pane fade" id="nav-withdraw" role="tabpanel" aria-labelledby="nav-withdraw-tab">
                  <>
                  {user&&user.IsLogin&&<WithdrawTable />}
                  </>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
