export const getAuthToken = () => {
    if (localStorage.getItem('authtoken')) {
        return localStorage.getItem('authtoken')
    }
    return '';
}

export const removeAuthToken = () => {
    localStorage.removeItem('authtoken');
}

export const setAuthToken = (token) => {
    localStorage.setItem('authtoken', token);
    return true
}