import axios from "../comman/axios.config";
import { json} from "react-router-dom";
import  config  from "../../src/config/config";
export const paymentintent = async (data) => {
    try {
      console.log(data,"data12334444443")
      let respData =await axios.post('/create-payment-intent', data)

      return {
        result: respData.data,
        message: respData.data.responseMessage,
        success: respData.data.success,
        err: respData.data.errors,
      };
    } catch (err) {
      return {
        err: returnErr(err),
      };
    }
  };

  export const updatepaymentintent = async (data) => {
    try {
      console.log(data,"data12334444443")
      let respData =await axios.post('/update-payment-intent', data)

      return {
        result: respData.data.result,
        message: respData.data.message,
        success: respData.data.success,
        err: respData.data.errors,
      };
    } catch (err) {
      return {
        err: returnErr(err),
      };
    }
  };


  export const payviatoken = async (data) => {
    try {
      console.log(data,"data12334444443")
      let respData =await axios.post('/pay-via-token', data)
      respData.data = JSON.parse(respData.data);

      return {
        result: respData.data.result,
        message: respData.data.message,
        hash: respData.data.hash,
        success: respData.data.success,
      };
    } catch (err) {
      console.log(err,"error");
      return {
        success: false,
        err: returnErr(err),
      };
    }
  };


  export const paymentdetails = async (data) => {
    console.log(data,"paymentrecipt");
    try {
    
      // let respData =await axios.post('/payment-receipt', data,)
      
      let respData = await axios({
        method: 'post',
        url: '/payment-receipt',
        data: data,
        headers:{
            Secretkey:config.SECRETKEY
        }
    })

      respData.data = JSON.parse(respData.data);
      console.log(respData,"response");
      return {
        result: respData.data.result,
        message: respData.data.message,
        success: respData.data.success,
      };
    } catch (err) {
      console.log(err,"error")
      return {
        err: returnErr(err),
      };
    }
  };




  function returnErr(err) {
    if (err.response && err.response.data && err.response.data.errors) {
      return err.response.data.errors;
    } else {
      return "";
    }
  }