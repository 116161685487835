import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addUser, removeUser } from '../redux-store/reducer'
import { getUser } from "../Api/comman";

export default function ReduxHelper() {
    const user = useSelector((state) => state.user)
    const dispatch = useDispatch();

    useEffect(() => {
        Checkuser();
    }, [])

    const Checkuser = (async () => {
        let token = localStorage.getItem("authtoken");
        console.log(user,"user-------123")
            console.log(user.IsLogin,"isLogin");
            if (!user.IsLogin && token) {
                let { result, message, success } = await getUser();
                if (success) {
                    dispatch(addUser(
                        {
                            IsLogin: true,
                            userId: result.userId,
                            email: result.email,
                            balance: result.tokens,
                            type:result.type
                        }
                    ))
                }

            }
            if (localStorage.getItem("account")&&user.IsLogin&&!user.address) {
                let address =localStorage.getItem("account");
                dispatch(addUser({
                    address: address,
                }
                ))
            }

            if (localStorage.getItem("amount")&&user.IsLogin&&!user.amount) {
                let amount =localStorage.getItem("amount");
                dispatch(addUser({
                    amount: amount,
                }
                ))
            }
    })
    return (
        <></>
    )
}