// import lib
import isEmpty from "../helper/isEmpty";
import Web3 from "web3";
import {validemail} from "../Api/comman"

export const depositvalidation = async(value,Balance) => {
  let errors = {};
  let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

  if (isEmpty(value.email)) {
    errors.email = "Please enter your email";
  }else if(!(emailRegex.test(value.email))){
    errors.email = "Please enter Valid email";
  }
  else if(1==1){
    let reqdata = {email: value.email};
    try{
    let {success, message}= await validemail(reqdata);
    console.log(success,"success")
    if(!success){
      errors.email = message;
    }
  }catch(err){
    errors.email = "server on Busy try after Later!!!";
  }
  }
  if (isEmpty(value.amount)) {
    errors.amount = "Please enter your token amount";
  }else if(parseInt(value.amount) >= Balance){
    errors.amount = "you don't have a amount of token";
  }

  if (isEmpty(value.address)) {
    errors.address = "Please enter your wallet address";
  } else if (!Web3.utils.isAddress(value.address)) {
    errors.address = "Please enter valid wallet address";
  }
  return errors;
};

export const RegisterValidation = async(value) => {
  let errors = {};
  let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
  let passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}/g;
  if (isEmpty(value.email)) {
    errors.email = "Please enter your email";
  }else if(!(emailRegex.test(value.email))){
    errors.email = "Please enter Valid email";
  }
  
  if (isEmpty(value.password)) {
    errors.password = "Please enter Password";
  } else if (!passwordRegex.test(value.password)) {
    errors.password =
      "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character and minimum 6 and maximum 18";
  } else if (value.password.length > 18) {
    errors.password =
      "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character and minimum 6 and maximum 18";
  }

  if (isEmpty(value.confirmpassword)) {
    errors.confirmpassword = "Please enter Confirm Password";
  }else if(value.password !== value.confirmpassword){
    errors.confirmpassword = "Password didn't match";
  }

  if (isEmpty(value.otp)) {
    errors.otp = "Please enter your OTP";
  }
  return errors;
};

export const loginValidation = async(value) => {
  let errors = {};
  let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

  if (isEmpty(value.email)) {
    errors.email = "Please enter your email";
  }else if(!(emailRegex.test(value.email))){
    errors.email = "Please enter Valid email";
  }else if(1==1){
    let reqdata = {email: value.email};
    try{
    let {success, message}= await validemail(reqdata);
    console.log(success,"success")
    if(!success){
      errors.email = message;
    }
  }catch(err){
    errors.email = "server on Busy try after Later!!!";
  }
  }
  
  if (isEmpty(value.password)) {
    errors.password = "Please enter Password";
  }
  return errors;
};

export const emailValidation = async(value) => {
  let errors = {};
  let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

  if (isEmpty(value.email)) {
    errors.email = "Please enter your email";
  }else if(!(emailRegex.test(value.email))){
    errors.email = "Please enter Valid email";
  }
  return errors;
};
export const changepasswordValidation = async(value) => {
  let errors = {};
  let passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}/g;

  if (isEmpty(value.password)) {
    errors.password = "Password Field Required";
  } else if (!passwordRegex.test(value.password)) {
    errors.password =
      "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character and minimum 6 and maximum 18";
  } else if (value.password.length > 18) {
    errors.password =
      "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character and minimum 6 and maximum 18";
  }

  if (isEmpty(value.confirmpassword)) {
    errors.confirmpassword = "ConfirmPassword Field Required";
  } else if (
    !isEmpty(value.confirmpassword) &&
    value.password != value.confirmpassword
  ) {
    errors.confirmpassword = "Passwords must match";
  }
  return errors;
};

export const OTPValidation = async(value) => {
  let errors = {};
  let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
  if (isEmpty(value.email)) {
    errors.email = "Please enter your email";
  }else if(!(emailRegex.test(value.email))){
    errors.email = "Please enter Valid email";
  }

  return errors;

}

