import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";
import {UserReport} from "../Api/comman"
// core components
import Header from "../components/Header/Header.js";
import FooterHome from "../components/Footer/FooterHome.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import styles from "../assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import moment from "moment";
import Wallet_Popup from "../Model/wallet_popup.jsx";
import { depositvalidation } from "../Validation/depositValidation";
import isEmpty from "../helper/isEmpty.js";
import INFINITYABI from "../ABI/infinity.json";
import Web3 from "web3";
import config from "../config/config";
import { toastAlert } from "../helper/toastAlert.js";
import { getRecords } from "../Api/comman";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);

// const columns = [
//   { field: "id", headerName: "S.No" },
//   {
//     field: "date",
//     headerName: "Date",    
//     editable: true,
//   },
//   {
//     field: "userid",
//     headerName: "User ID",    
//     editable: true,
//     width: 250,
//   },
//   {
//     field: "email",
//     headerName: "Email",
//     editable: true,
//     width: 300,
//   },
//   {
//     field: "amount",
//     headerName: "Amount",
//     editable: true,
//   },
//   {
//     field: "report",
//     headerName: "Report",
//     editable: false,
//   },   
// ];

// const rows = [
//   { id: 1, date:"03/29/2023", userid: "6427ffed9c2ef13a70f806e3", email: "surendran.wealwin@gmail.com", amount: 35, report:  "View" }, 
// ];


export default function PartnerSiteReport(props) {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [address, setaddress] = useState();
  const [token, settoken] = useState();
  const [Records, setRecords] = useState([]);
  const { ...rest } = props;
  const navigate = useNavigate();
  const columns = [
    // { field: 'id', headerName: 'ID', width: 30 },
    {
        field: 'date',
        headerName: 'Date',
        width: 300,
        headerAlign: 'center',
      align: 'center',
        valueGetter: (params) => moment(params.row.date).format('L'),
    },
    {
        field: 'userId',
        headerName: 'UserId',
        // type: 'number',
        width: 310,
        headerAlign: 'center',
      align: 'center',
        // editable: true,
    },
    {
        field: 'email',
        headerName: 'Email',
        // type: 'number',
        width: 280,
        headerAlign: 'center',
      align: 'center',
        // editable: true,
    },
    {
        field: 'amount',
        headerName: 'Amount',
        // type: 'number',
        width: 180,
        headerAlign: 'center',
      align: 'center',
        // editable: true,
    },
    {
      field: ' ',
      headerName: 'Report',
      // sortable: 'false',
      width: 280,
      headerAlign: 'center',
      align: 'center',
      // disableClickEventBubbling: true,
      renderCell: (params) => {

          const viewHandler = async () => {
              let data = params.row.userId
              let sitename = localStorage.getItem("sitename")
              console.log("VIewHandler: ", params.row.userId)
                  navigate("/transaction-report/"+data)
          }

          return (
              <>
                  <p
                        style={{
                          backgroundColor:'steelblue',
                          borderRadius:'50px',
                          padding: '6px 12px',
                          color:"white",
                          cursor: 'pointer',
                          marginTop:"25px"
                      }}
                      onClick={viewHandler}
                  >
                      View
                  </p>
              </>
          )
      }
  }
  
]


  useEffect(() => {
    console.log(user, "redux-----12");
  }, [user]);
  const getData = async () => {

    let data = localStorage.getItem("partnersite")
    let { result, status } = await UserReport(data);

    if (status) {
        let respData = result.map((item, key) => {
            return {
                id: key,
                ...item,
            }
        })
    console.log(respData,'respData')
        setRecords(respData)
        
    }


}

console.log("BEfore UseEffect: ", Records)


useEffect(() => {
    getData()
}, [])

  return (
    <div>
      <ScrollToTopOnMount />
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo" />}
        rightLinks={
          <HeaderLinks
            address={address}
            settoken={settoken}
            token={token}
            setaddress={setaddress}
          />
        }
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
      <div className="page-header" name="home">
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={12} lg={12} className="m-auto">
              <div className="buy_box_inner">
                <h2>Partner Site Report</h2>
                {/* <DataGrid
                  // rows={rows}
                  columns={columns}
                  pageSize={5}
                  checkboxSelection
                  disableSelectionOnClick
                /> */}
                 {Records.length > 0 && (
                    <DataGrid
                        rows={Records}
                        columns={columns}
                        // slots={{
                        //     toolbar: CustomToolbar,
                        // }}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        disableExporting
                    />
                )}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
