import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "../components/Header/Header.js";
import FooterHome from "../components/Footer/FooterHome.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import styles from "../assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import { Button, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import {useDispatch,useSelector } from "react-redux"
import { addUser,removeUser } from "../redux-store/reducer.js";
import {getbalance,getipdetails} from "../Api/comman.js"
import { payviatoken } from "../Api/payment-gateway.js";
import { toast } from "react-toastify";

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);

export default function Receipt(props) {
  const classes = useStyles();
  const location = useLocation();
  const {payamount} = location.state;
  const user = useSelector((state)=>state.user)
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const [expanded, setExpanded] = React.useState('panel1');
  const [token, settoken] = useState();
  const [address, setaddress] = useState();
  const [amount, setamount] = useState(payamount);
  const [tokennn, settokennn] = useState();
  const [balance, setbalance] = useState();
  const [success, setsuccess] = useState(false);

  const navigate = useNavigate();

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const Getbalance=(async()=>{
    let {result,success,message}= await getbalance()
    if(success){
      setbalance(result.balance);
      if(amount>result.balance){
      navigate('/buy')
      toast.warn("You have low balance Please buy the token");
      }
    }
  })

  useEffect(() => {
    setCount(1);
    Getbalance()
    if(user.amount){
      setamount(user.amount);
    }
  }, [count,user.amount]);

  const handlepayment=(async()=>{
    if(amount>balance){
      toast.warn("You have low balance Please buy the token");
      return false;
    }
    let {ip,city,region,country_name} = await getipdetails();
    let info = {
      ip:ip,
      city: city,
      state: region,
      country: country_name
    }
    let userdata = {
      email:user.email,
      tokens:amount,
      userId: user.userId,
      url:user.url,
      site:user.website,
      item:user.item,
    };

    let reqdata ={info:info,user:userdata}
    let {result,success,message,hash} = await payviatoken(reqdata);
    console.log(result,success,hash,"responscesfsaf")
    if(success){
      setsuccess(true);
      toast.success("Payment successfull!!!");
      dispatch(addUser({
        balance:result
      }))
      let redirecurl = `${user.url}?hash=${hash}&status=success`
      console.log(redirecurl,'url');
      window.location.replace(redirecurl)
      localStorage.removeItem('weuueiq')
      localStorage.removeItem('hash')
      localStorage.removeItem("amount")
    }else{
      let redirecurl = `${user.url}?hash=""&status=failed`
      window.location.replace(redirecurl)
      console.log(redirecurl,'url');
      toast.error(message);
    }
  })

  const handlecancel =(()=>{
    let redirecurl = `${user.url}?hash=""&status=failed`
      window.location.replace(redirecurl)
      console.log(redirecurl,'url');
      toast.error("Payment Cancelled!!!");
  })

  const { ...rest } = props;

  const handlewithdraw = (() => {
    navigate('/withdraw', { state: { address: address } });
  });
  console.log(address, "address----123")
  return (
    <div>

      <ScrollToTopOnMount />
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo" />}
        rightLinks={<HeaderLinks address={address} settoken={settoken}  token={token} setaddress={setaddress}/>}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
      <div className="page-header" name="home">
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={10} lg={8} className="m-auto">
              <div className="buy_box_inner">
                  <div className="text-center mb-4">
                  {success?<img src={require("../assets/images/receipt_img.png")} alt="logo" className="img-fluid" />:
                    <img src={require("../assets/images/confirm.png")} alt="logo" width="170px" className="img-fluid" />}
                  </div>
                  <h2>Confirm the Payment: {amount?<>{amount}</>:0} INW</h2>
                  <p><h5>Your Balance: {balance?<>{balance}</>:0} INW</h5></p>
                  <p>Receive, send, store, exchange, and pay in cryptocurrency with a security audited crypto wallet.</p>
                  <div className="btn_grps">
                    <button className="primary_btn" onClick={handlecancel} >Cancel</button>
                    <button className="primary_btn" disabled={amount>0?false:true} onClick={handlepayment}>Pay</button>
                  </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
