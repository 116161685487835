import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { setAuthorization } from "../comman/axios.config.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "../components/Header/Header.js";
import FooterHome from "../components/Footer/FooterHome.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import styles from "../assets/jss/material-kit-react/views/home";
import { Link } from "react-router-dom";
import {useDispatch,useSelector} from "react-redux"
import { checkUser, getcurrencyList, sample } from "../Api/comman"

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);
var inituser = { email: "", userId: "" }
const dashboardRoutes = [];
export default function Buy(props) {
  const classes = useStyles();
  const user = useSelector((state)=>state.user)
  const [searchParams, setSearchParams] = useSearchParams();
  const payment_secret = searchParams.get("payment");
  const navigate = useNavigate();
  const [currencyList, setcurrencyList] = useState();
  const [userDetails, setuserDetails] = useState(inituser);
  const [recieveToken, setrecieveToken] = useState();
  const [tokenLimit, settokenLimit] = useState(0);
  const [Amount, setAmount] = useState();
  const [currency, setcurrency] = useState("");
  const [token, settoken] = useState();
  const [address, setaddress] = useState();

  const { ...rest } = props;

  useEffect(() => {
    // if (payment_secret) {
    //   myuser();
    // }
    init()
  }, [payment_secret])

  const myuser = (async () => {
    localStorage.setItem('authtoken', `Bearer ${payment_secret}`)
    setAuthorization(`Bearer ${payment_secret}`);
    let { result, user, message, success, err } = await checkUser();
    localStorage.setItem('authtoken', result);
    let userdata = { ...userDetails, ...user }
    setuserDetails(userdata);
    console.log(result, "result")
    await afterlogin(result)
  })
  const afterlogin = (async (data) => {
    let { result } = await sample(data);
    console.log(result);
  })

  const init = (async () => {
    let { result, message, success, err } = await getcurrencyList();
    if (success) {
      setcurrencyList(result);
      setcurrency(result[0].symbol);
      settokenLimit(result[0].tokenvalue);
    } else {
      setcurrencyList([])
      console.log(message, "messgae");
    }
  })

  const Selectcurrecy = ((e) => {
    let { value } = e.target;
    setcurrency(currencyList[value].symbol);
    settokenLimit(currencyList[value].tokenvalue);
    if (Amount) {
      Calculate(Amount, currencyList[value].tokenvalue);
    }

  })
  const Calculate = ((value, limit) => {
    let calctoken = value * limit;
    setrecieveToken(calctoken)
  })
  const CalculateToken = ((e) => {
    let { value } = e.target;
    setAmount(value)
    let calctoken = value * tokenLimit;
    setrecieveToken(calctoken)
  })

  const CalculateAmount = ((e) => {
    let { value } = e.target;
    setrecieveToken(value)
    let calcamount = value / tokenLimit;
    console.log()
    setAmount(calcamount)
  })

  const submit = (async () => {
    navigate('/payment', {
      state: {
        amount: Amount,
        currency: currency,
        email: user.email,
        userId: user.userId,
        tokens: recieveToken,
      }
    });
  })

  console.log(payment_secret, "userData");
  return (
    <div>
      <ScrollToTopOnMount />
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo"/>}
        rightLinks={<HeaderLinks address={address} settoken={settoken} token={token} setaddress={setaddress} />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
      <div className="page-header-buy">
        <div className={classes.container}>
          <GridContainer className="align-items-center">
            <GridItem md={12} lg={6}>
              <h1>Buy a <span>CT Tokens</span> Easy With Fiat Currency</h1>
              <p>Receive, send, store, exchange, and pay in cryptocurrency with a security audited crypto wallet.</p>
            </GridItem>
            <GridItem md={12} lg={6}>
              <div className="buy_box">
                <div className="buy_box_inner">
                  <div class="form-group">
                    <select onChange={Selectcurrecy} class="form-control" id="exampleFormControlSelect2">
                      {currencyList && currencyList.length > 0 && currencyList.map((item, indx) => {
                        return (
                          <option value={indx}>{item.name}</option>
                        )
                      })}
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="amount">Enter Amount</label>
                    <input type="number" class="form-control" id="amount" value={Amount} placeholder="Enter Amount" onChange={CalculateToken} />
                  </div>
                  <div class="form-group">
                    <label for="token">Received Token</label>
                    <div class="input-group mb-3">
                      <input type="number" id="token" class="form-control" aria-describedby="basic-addon2" value={recieveToken} onChange={CalculateAmount} />
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2">CT</span>
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-4">
                    <button className="primary_btn" onClick={submit}>Buy Now</button>
                    <small>No extra fees</small>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>

    </div>
  );
}
