import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "../components/Header/Header.js";
import FooterHome from "../components/Footer/FooterHome.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import styles from "../assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import Wallet_Popup from "../Model/wallet_popup.jsx";
import { depositvalidation } from "../Validation/depositValidation";
import isEmpty from "../helper/isEmpty.js";
import INFINITYABI from "../ABI/infinity.json";
import Web3 from "web3";
import config from "../config/config";
import { toastAlert } from "../helper/toastAlert.js";
import { updatedepositetoken } from "../Api/comman"
// Datatable
import DataTable from 'react-data-table-component';


const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);
const initdata = {
  email: "",
  address: "",
  amount: "",
}
export default function Reports(props) {
  const classes = useStyles();
  const [count, setCount] = useState(1);
  const [expanded, setExpanded] = React.useState("panel1");
  const [address, setaddress] = useState();
  const [depositdata, setdepositdata] = useState(initdata)
  const [tokenbalance, settokenbalance] = useState();
  const [depositerrors, setdepositerrors] = useState({});
  const [loading, setloading] = useState(false)



  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setCount(1);
  }, [count]);

  const { ...rest } = props;

  const handlechange = (async (e) => {
    let { id, value } = e.target;
    setdepositdata({ ...depositdata, ...{ [id]: value } });
    if (isEmpty(depositdata.address)) {
      setdepositdata({ ...depositdata, ...{ ["address"]: address } });
    }
  })

  const handlesubmit = (async () => {
    try {
      let valid = await depositvalidation(depositdata, tokenbalance);
      console.log(valid, 'errors')
      if (!isEmpty(valid)) {
        setdepositerrors(valid);
        return false;
      }
      setloading(true)
      let web3 = new Web3(window.ethereum);
      let tokencontract = new web3.eth.Contract(INFINITYABI, config.INFINITEWAY);
      let amountoftoken = (depositdata.amount * 1e18).toString();
      try {
        let balanceof = await tokencontract.methods.transfer(config.ADMINADDRESS, amountoftoken).send({ from: address })
      } catch (err) {
        toastAlert("error", "Deposit Rejected!!!");
        setloading(false)
        return false;
      }
      let reqdata = { email: depositdata.email, tokens: depositdata.amount };
      let { success } = await updatedepositetoken(reqdata);
      if (success) {
        toastAlert("success", "Token Deposit Successfully!!!");
        setloading(false)
      }
    } catch (err) {
      setloading(false)
      toastAlert("error", "Deposit Failed!!!");

      console.log(err, "error");
    }
  })

  // Transaction Report Table
  const data = [
    { date: "Dec 29, 2020", time: "05:13AM", transactionid: "257896", userid: 'ASC257896', useremail: 'test@testmail.com', website: "www.website.com", item: "Lorem ipsum is a simple content", amount: "$825.00", paymentMethod: "Moonpay", status: <span className="greenText">Success</span>, invoiceReceipt: <button className="menu_btn tableButton">Download</button> },
    { date: "Dec 29, 2020", time: "05:13AM", transactionid: "257896", userid: 'ASC257896', useremail: 'test@testmail.com', website: "www.website.com", item: "Lorem ipsum is a simple content", amount: "$825.00", paymentMethod: "Moonpay", status: <span className="greenText">Success</span>, invoiceReceipt: <button className="menu_btn tableButton">Download</button> },
    { date: "Dec 29, 2020", time: "05:13AM", transactionid: "257896", userid: 'ASC257896', useremail: 'test@testmail.com', website: "www.website.com", item: "Lorem ipsum is a simple content", amount: "$825.00", paymentMethod: "Moonpay", status: <span className="greenText">Success</span>, invoiceReceipt: <button className="menu_btn tableButton">Download</button> },
    { date: "Dec 29, 2020", time: "05:13AM", transactionid: "257896", userid: 'ASC257896', useremail: 'test@testmail.com', website: "www.website.com", item: "Lorem ipsum is a simple content", amount: "$825.00", paymentMethod: "Moonpay", status: <span className="greenText">Success</span>, invoiceReceipt: <button className="menu_btn tableButton">Download</button> },
    { date: "Dec 29, 2020", time: "05:13AM", transactionid: "257896", userid: 'ASC257896', useremail: 'test@testmail.com', website: "www.website.com", item: "Lorem ipsum is a simple content", amount: "$825.00", paymentMethod: "Moonpay", status: <span className="greenText">Success</span>, invoiceReceipt: <button className="menu_btn tableButton">Download</button> },
    { date: "Dec 29, 2020", time: "05:13AM", transactionid: "257896", userid: 'ASC257896', useremail: 'test@testmail.com', website: "www.website.com", item: "Lorem ipsum is a simple content", amount: "$825.00", paymentMethod: "Moonpay", status: <span className="greenText">Success</span>, invoiceReceipt: <button className="menu_btn tableButton">Download</button> },
    { date: "Dec 29, 2020", time: "05:13AM", transactionid: "257896", userid: 'ASC257896', useremail: 'test@testmail.com', website: "www.website.com", item: "Lorem ipsum is a simple content", amount: "$825.00", paymentMethod: "Moonpay", status: <span className="greenText">Success</span>, invoiceReceipt: <button className="menu_btn tableButton">Download</button> },
    { date: "Dec 29, 2020", time: "05:13AM", transactionid: "257896", userid: 'ASC257896', useremail: 'test@testmail.com', website: "www.website.com", item: "Lorem ipsum is a simple content", amount: "$825.00", paymentMethod: "Moonpay", status: <span className="greenText">Success</span>, invoiceReceipt: <button className="menu_btn tableButton">Download</button> },
    { date: "Dec 29, 2020", time: "05:13AM", transactionid: "257896", userid: 'ASC257896', useremail: 'test@testmail.com', website: "www.website.com", item: "Lorem ipsum is a simple content", amount: "$825.00", paymentMethod: "Moonpay", status: <span className="greenText">Success</span>, invoiceReceipt: <button className="menu_btn tableButton">Download</button> },
    { date: "Dec 29, 2020", time: "05:13AM", transactionid: "257896", userid: 'ASC257896', useremail: 'test@testmail.com', website: "www.website.com", item: "Lorem ipsum is a simple content", amount: "$825.00", paymentMethod: "Moonpay", status: <span className="greenText">Success</span>, invoiceReceipt: <button className="menu_btn tableButton">Download</button> },

  ];
  const columns = [
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
    },
    {
      name: 'Time',
      selector: 'time',
      sortable: true,
    },
    {
      name: 'Transaction ID',
      selector: 'transactionid',
      sortable: false,
    },
    {
      name: 'User ID',
      selector: 'userid',
      sortable: false,
    },
    {
      name: 'Email',
      selector: 'useremail',
      sortable: false,
    },
    {
      name: 'Website',
      selector: 'website',
      sortable: false,
    },
    {
      name: 'Item',
      selector: 'item',
      sortable: false,
    },
    {
      name: 'Amount',
      selector: 'amount',
      sortable: false,
    },
    {
      name: 'Payment Method',
      selector: 'paymentMethod',
      sortable: false,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: false,
    },
    {
      name: 'Invoice Receipt',
      selector: 'invoiceReceipt',
      sortable: false,
      width: "150px"
    },
  ];

  return (
    <div>
      <ScrollToTopOnMount />
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />

      <Wallet_Popup address={address} setaddress={setaddress} settokenbalance={settokenbalance} />
      <div className="page-header" name="home">
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={12} lg={12} className="m-auto">
              <div className="buy_box_inner">
                <h2>Reports</h2>
                <nav>
                  <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                    <a class="nav-item nav-link active" id="nav-transaction-tab" data-toggle="tab" href="#nav-transaction" role="tab" aria-controls="nav-transaction" aria-selected="true">Transaction Report</a>
                    <a class="nav-item nav-link" id="nav-payment-tab" data-toggle="tab" href="#nav-payment" role="tab" aria-controls="nav-payment" aria-selected="false">Payment Report</a>
                    <a class="nav-item nav-link" id="nav-deposit-tab" data-toggle="tab" href="#nav-deposit" role="tab" aria-controls="nav-deposit" aria-selected="false">Deposit Report </a>
                    <a class="nav-item nav-link" id="nav-withdraw-tab" data-toggle="tab" href="#nav-withdraw" role="tab" aria-controls="nav-withdraw" aria-selected="false">Withdraw Report </a>
                  </div>
                </nav>
                <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                  <div class="tab-pane fade show active" id="nav-transaction" role="tabpanel" aria-labelledby="nav-transaction-tab">
                    <DataTable columns={columns} data={data} noHeader />
                  </div>
                  <div class="tab-pane fade" id="nav-payment" role="tabpanel" aria-labelledby="nav-payment-tab">
                    Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
                  </div>
                  <div class="tab-pane fade" id="nav-deposit" role="tabpanel" aria-labelledby="nav-deposit-tab">
                    Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
                  </div>
                  <div class="tab-pane fade" id="nav-withdraw" role="tabpanel" aria-labelledby="nav-withdraw-tab">
                    Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
