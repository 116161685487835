import axios from "../comman/axios.config";
import { json } from "react-router-dom";

export const getcurrencyList = async (data) => {
    try {
      let respData =await axios.get('/currency-list', data)
      respData.data = JSON.parse(respData.data);
      console.log(respData,"response");
      return {
        result: respData.data.list,
        message: respData.data.message,
        success: respData.data.success,
      };
    } catch (err) {
      console.log(err,"error")
      return {
        err: returnErr(err),
      };
    }
  };

  


export const getipdetails=async()=>{
  try{
  var response = await fetch('https://ipapi.co/json')
  var resp = response.json();
  return resp;
  }catch(err){
    return err;
  }
};



  export const checkUser = async (data) => {
    console.log(data,"data1233333");
    try {
      let respData =await axios.get('/check-user')
      respData.data = JSON.parse(respData.data);
      console.log(respData,"response");
      return {
        result: respData.data.result,
        user: respData.data.user,
        message: respData.data.message,
        success: respData.data.success,
      };
    } catch (err) {
      console.log(err,"error")
      return {
        err: returnErr(err),
      };
    }
  };

  export const sample = async (data) => {
    console.log(data,"data1233333");
    try {
      let respData =await axios.post('/sample', data)
      respData.data = JSON.parse(respData.data);
      console.log(respData,"response");
      return {
        result: respData.data.result,
        message: respData.data.message,
        success: respData.data.success,
      };
    } catch (err) {
      console.log(err,"error")
      return {
        err: returnErr(err),
      };
    }
  };

  export const updatedepositetoken = async (data) => {
    try {
      let respData =await axios.post('/update-deposit-token', data)
      respData.data = JSON.parse(respData.data);
      return {
        message: respData.data.message,
        result: respData.data.result,
        success: respData.data.success,
      };
    } catch (err) {
      console.log(err,"error")
      return {
        err: returnErr(err),
      };
    }
  };
  

  export const validuser = async (data) => {
    try {
      let respData =await axios.post('/valid-user', data)
      respData.data = JSON.parse(respData.data);
      return {
        result: respData.data.result,
        message: respData.data.message,
        success: respData.data.success,
      };
    } catch (err) {
      console.log(err,"error12377778")
      return {
        err: returnErr(err),
      };
    }
  };
  
  export const validemail = async (data) => {
    try {
      let respData =await axios.post('/valid-email', data)
      respData.data = JSON.parse(respData.data);
      return {
        result: respData.data.result,
        message: respData.data.message,
        success: respData.data.success,
      };
    } catch (err) {
      console.log(err,"error12377778")
      return {
        err: returnErr(err),
      };
    }
  };

  export const sendEmailOTP = async (data) => {
    try {
      let respData =await axios.post('/send-otp', data)
      respData.data = JSON.parse(respData.data);
      return {
        result: respData.data.result,
        message: respData.data.message,
        success: respData.data.success,
      };
    } catch (err) {
      console.log(err,"error12377778")
      return {
        err: returnErr(err),
      };
    }
  };

  export const withdraw_Token = async (data) => {
    try {
      let respData =await axios.post('/withdraw-token', data)
      respData.data = JSON.parse(respData.data);
      return {
        result: respData.data.result,
        message: respData.data.message,
        success: respData.data.success,
      };
    } catch (err) {
      console.log(err,"error12377778")
      return {
        err: returnErr(err),
      };
    }
  };

  export const getservicefee = async (data) => {
    try {
      let respData =await axios.get('/get-servicefee')
      respData.data = JSON.parse(respData.data);
      return {
        result: respData.data.result,
        message: respData.data.message,
        success: respData.data.success,
      };
    } catch (err) {
      console.log(err,"error12377778")
      return {
        err: returnErr(err),
      };
    }
  };

  export const Regiseter_User = async (data) => {
    try {
      let respData =await axios.post('/register-user', data)
      respData.data = JSON.parse(respData.data);
      return {
        result: respData.data.result,
        message: respData.data.message,
        success: respData.data.success,
        errors:respData.data.errors,
      };
    } catch (err) {
      console.log(err,"error12377778")
      return {
        err: returnErr(err),
      };
    }
  };

  export const getRecords = async (data) => {
    try {
      let respData =await axios.post('/get-Records', data)
      respData.data = JSON.parse(respData.data);
      return {
        result: respData.data.list,
        message: respData.data.message,
        success: respData.data.success,
      };
    } catch (err) {
      console.log(err,"error12377778")
      return {
        errors: returnErr(err),
      };
    }
  };

  export const getUser = async (data) => {
    try {
      let respData =await axios.get('/get-user', data)
      respData.data = JSON.parse(respData.data);
      return {
        result: respData.data.result,
        message: respData.data.message,
        success: respData.data.success,
      };
    } catch (err) {
      console.log(err,"error12377778")
      return {
        errors: returnErr(err),
      };
    }
  };

  
  export const getbalance = async (data) => {
    try {
      let respData =await axios.get('/get-balance')
      respData.data = JSON.parse(respData.data);
      return {
        result: respData.data.result,
        message: respData.data.message,
        success: respData.data.success,
      };
    } catch (err) {
      console.log(err,"error12377778")
      return {
        errors: returnErr(err),
      };
    }
  };



  export const Login_User = async (data) => {
    try {
      let respData =await axios.post('/login-user', data)
      respData.data = JSON.parse(respData.data);
      return {
        result: respData.data.result,
        user: respData.data.user,
        message: respData.data.message,
        success: respData.data.success,
        data: respData.data.data,
        errors:respData.data.errors,
      };
    } catch (err) {
      console.log(err,"error12377778")
      return {
        success: false,
        message: "Error on server",
        err: returnErr(err),
      };
    }
  };

  export const Forgotpassword = async (data) => {
    try {
      let respData =await axios.post('/forgot-password', data)
      console.log(respData,'respData')
    
      return {
        result: respData.data.result,
        message: respData.data.message,
        success: respData.data.success,
        errors:respData.data.errors,
      };
    } catch (err) {
      console.log(err,"error12377778")
      return {
        err: returnErr(err),
      };
    }
  };
  export const checktoken = async (data) => {
    try {
      let respData =await axios.post('/check-token', data)
      
      console.log(respData,'respData256')
      return {
        result: respData.data.result,
        user: respData.data.user,
        message: respData.data.message,
        success: respData.data.success,
        errors:respData.data.errors,
      };
    } catch (err) {
      console.log(err,"error12377778")
      return {
        err: returnErr(err),
      };
    }
  };
  export const Resetpassword = async (data) => {
    try {
      let respData =await axios.post('/reset-password', data)
      
      console.log(respData,'respData256')
      return {
        result: respData.data.result,
        user: respData.data.user,
        message: respData.data.message,
        success: respData.data.success,
        errors:respData.data.errors,
      };
    } catch (err) {
      console.log(err,"error12377778")
      return {
        err: returnErr(err),
      };
    }
  };
  export const PartnerDepositChart = async (data) => {
    console.log('datsssssfffa: ', data);
    try {
     
        let respData =await axios.post('/deposit-partchart', data)
        return {
            result: respData.data.result
        }
    } catch (err) {
        return {
          err: returnErr(err),
        }
    }
  }
  export const PartnerBuyChart = async (data) => {
    try {
       
        let respData =await axios.post('/buy-partchart', data)
        return {
            result: respData.data.result
        }
    } catch (err) {
        return {
            errors: returnErr(err),
            status: err.response.data.status,
            message: err.response.data.message
        }
    }
}
  export const partnerDetails = async (data) => {
    try {
        let respData =await axios.post('/partner', data)
        return {
            message: respData.data.message,
            status: respData.data.status,
            totalUsers: respData.data.totalUsers
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}
export const PartnerWithdrawChart = async (data) => {
  try {
    
      let respData =await axios.post('/withdraw-partchart', data)
      return {
          result: respData.data.result
      }
  } catch (err) {
      return {
          errors: returnErr(err),
          status: err.response.data.status,
          message: err.response.data.message
      }
  }
}
export const PartnerPaymentChart = async (data) => {
  try {
    
      let respData =await axios.post('/payment-partchart', data)
      return {
          result: respData.data.result
      }
  } catch (err) {
      return {
          errors: returnErr(err),
          status: err.response.data.status,
          message: err.response.data.message
      }
  }
}
export const UserReport = async (data, search) => {
  try {
       
         var datas =  { sitename: data, search: search }
      let respData =await axios.post('/userReport', datas)
      return {
          message: respData.data.message,
          status: respData.data.status,
          result: respData.data.result
      }
  } catch (err) {
      return {
          errors: returnErr(err),
      }
  }
}
export const EmailDetails = async (data, value) => {
  try {
    
      var datas =  { userId: data, search: value }
      let respData =await axios.post('/emailDetails', datas)
      return {
          message: respData.data.message,
          status: respData.data.status,
          result: respData.data.result
      }
  } catch (err) {
      return {
          errors: returnErr(err),
      }
  }
}
export const verifyHash = async (data) => {
  try {
    let respData =await axios.post('/verify-hash', data)
    respData.data = JSON.parse(respData.data);
    console.log( respData.data,' respData.data')
    return {
      result: respData.data,
      message: respData.data.message,
      success: respData.data.success,
    };
  } catch (err) {
    console.log(err,"error12377778")
    return {
      errors: returnErr(err),
    };
  }
};
  function returnErr(err) {
    if (err.response && err.response.data && err.response.data.errors) {
      return err.response.data.errors;
    } else {
      return "";
    }
  }

  
