import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import CheckoutForm from "./CheckoutForm";
import "../App.css";
import { paymentintent } from "../Api/payment-gateway";
import config from '../../src/config/config'
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(config.STRIPEKEY);

export default function StripePayment() {
  const location = useLocation();
  const {amount, currency,userId,email,tokens} = location.state;
  const [clientSecret, setClientSecret] = useState("");
  const [payment_intent_id, setpayment_intent_id] = useState("");


  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetchsecret();
    // fetch("/v1/create-payment-intent", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({ items: [{ id: "xl-tshirt", amount: location.state.amount,currency: location.state.currency }] }),
    // })
    //   .then((res) => res.json())
    //   .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const fetchsecret = async () => {
    let data = { items: [{ id: userId,description:"Purchase CT tokens", amount: amount,currency: currency }] };
    console.log(data,"data.................");
    let { result } = await paymentintent(data);
    console.log(result, "payment result");
    setClientSecret(result.clientSecret);
    setpayment_intent_id(result.id);
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div>
      <div className="page-header" name="home">
        <div className="container">
          <GridContainer>
            <GridItem md={12} lg={8} className="m-auto">
              <div className="fullwidth_box">
                {clientSecret && (
                  <Elements displayName="Infinite" options={options} stripe={stripePromise}>
                    <CheckoutForm payment_intent_id={payment_intent_id} clientSecret={clientSecret} userId={userId} tokens={tokens}/>
                  </Elements>
                )}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
