import React, { useState, useRef,useEffect } from "react";
import Web3 from 'web3';
import config from "../config/config"
import { toastAlert } from "../helper/toastAlert"
import WalletConnectProvider from "@walletconnect/web3-provider";
import INFINITYABI from "../ABI/infinity.json";
import {useDispatch,useSelector} from "react-redux";
import { addUser } from "../redux-store/reducer";
import { useConnect } from 'wagmi'

import { type PublicClient, type WalletClient, useWalletClient, usePublicClient, useDisconnect, useNetwork ,useSwitchNetwork} from 'wagmi'
import {
  useEthersSigner,
  walletClientToSigner,
} from "../pages/ethersConnect.js";

function Wallet_Popup({ address, settoken, token, setaddress,settokennn }) {

  const { connect, connectors, error, isLoading, pendingConnector } =
        useConnect()
        const { disconnect,isSuccess,status } = useDisconnect()
        const { chain } = useNetwork()
        let chainId = config.NETWORKVERSION;
        console.log(chainId,config.NETWORKVERSION,'config.NETWORKVERSION')
        // if(chain && chain.id != config.NETWORKVERSION){
        //   toastAlert("error", "Choose Bsc Network",'err') 
        //   setTimeout(function () {
        //     disconnect()
        //           }, 1000);
            
      
        // }
        const { data: walletClient } = useWalletClient({ chainId });


        async function setConnection() {
        if (walletClient) {
       
    
          var { signer, transport } = walletClientToSigner(walletClient);
    
          var web3 = new Web3(transport);
          const getaccounts = await web3.eth.requestAccounts();
          let version =await web3.eth.net.getId();
             console.log(version, config.NETWORKVERSION, "datatattagt")
             if (version === config.NETWORKVERSION) {
               try {
                 let tokencontract = new web3.eth.Contract(INFINITYABI, config.INFINITEWAY);
                 let balanceof = await tokencontract.methods.balanceOf(getaccounts[0]).call()
                 console.log(balanceof, "balanceof");
                 var value = balanceof / config.decimalValues;
               } catch (err) {
                 console.log(err, "error")
               }
               settoken(value);
               dispatch(addUser({address:getaccounts[0],token:value}))
               
               setaddress(getaccounts[0]);
               localStorage.setItem('wallettype', 'metamask');
               localStorage.setItem('account', getaccounts[0]);
            
               //   window.location.reload(false)
             } else {
               toastAlert('error', "Connect BSC Mainnet", 'wallet');
              
             }

        }
        else{
          console.log("elseee")
        }
        
      }
  const timerRef = useRef(null);
  // const [address,setaddress] = useState()
  const user = useSelector((state)=>state.user)
  const dispatch = useDispatch()
  // console.log("RpcUrl", config.RPCURL)
  // async function connectMetamask() {
  //   console.log('iam calling')
  //   if (window.ethereum) {
  //     var web3 = new Web3(window.ethereum);
  //     const getaccounts = await web3.eth.requestAccounts();
  //    let version =await web3.eth.net.getId();
  //       console.log(version, config.NETWORKVERSION, "datatattagt")
  //       if (version === config.NETWORKVERSION) {
  //         try {
  //           let tokencontract = new web3.eth.Contract(INFINITYABI, config.INFINITEWAY);
  //           let balanceof = await tokencontract.methods.balanceOf(getaccounts[0]).call()
  //           console.log(balanceof, "balanceof");
  //           var value = balanceof / config.decimalValues;
  //         } catch (err) {
  //           console.log(err, "error")
  //         }
  //         settoken(value);
  //         dispatch(addUser({address:getaccounts[0],token:value}))
  //         toastAlert('success', "Wallet connected 🦊 ", 'wallet');
  //         setaddress(getaccounts[0]);
  //         localStorage.setItem('wallettype', 'metamask');
  //         localStorage.setItem('account', getaccounts[0]);
  //         window.$('#exampleModal').modal('hide')
  //         //   window.location.reload(false)
  //       } else {
  //         toastAlert('error', "Connect BSC Mainnet", 'wallet');
  //         try {
  //           await web3.currentProvider.request({
  //             method: "wallet_switchEthereumChain",
  //             params: [{ chainId: Web3.utils.toHex(parseInt(config.NETWORKVERSION)) }],
  //           });
  //           toastAlert('success', "Wallet connected 🦊 ", 'wallet');
  //           window.$('#exampleModal').modal('hide')
  //           setaddress(getaccounts[0]);
  //           dispatch(addUser({address:getaccounts[0]}))
  //           localStorage.setItem('wallettype', 'metamask');
  //           localStorage.setItem('account', getaccounts[0]);
  //           //   window.location.reload(false);
  //         } catch (switchError) {
  //           console.log("some error on switching", switchError);
  //         }
  //       }
  //   } else {
  //     toastAlert('error', "please add metamask Extension", 'wallet');
  //     return;
  //   }
  // }
  // async function connectTrustwallet() {
  //   var web3 = new Web3(config.RPCURL);
  //   var provider1 = new WalletConnectProvider({
  //     rpc: {
  //       [config.livechainid]: config.RPCURL
  //     },
  //     chainId: config.livechainid
  //   });
  //   provider1.on("connect", () => {
  //     setTimeout(() => window.$('#exampleModal').modal('hide'), 600);
  //     setTimeout(() => window.location.reload(false), 1200);
  //   });
  //   await provider1.enable();
  //   web3 = new Web3(provider1);
  //   web3.eth.net.getId().then(async res => {
  //     if (res == 56) {
  //       var getaccounts = await web3.eth.getAccounts();
  //       localStorage.setItem('wallettype', "trust");
  //       localStorage.setItem('account', getaccounts[0]);
  //       toastAlert('success', "Wallet connected 🛡", 'wallet');
  //     } else {
  //       toastAlert('error', "Connect BSC Mainnet", 'excerciseerr');
  //       setaddress("")
  //       return false;
  //     }
  //   });
  // }


  // window.addEventListener("load", (event) => {
  //   event.preventDefault();
  //   console.log("addEventListener", event);
  //   if (window.ethereum) {
  //     window.ethereum.on("accountsChanged", function (accounts) {
  //       // window.location.reload(false);
  //       setaddress(accounts[0])
  //       dispatch(addUser({address:accounts[0]}))
  //       if (timerRef.current) {
  //         clearTimeout(timerRef.current);
  //       }
  //       timerRef.current = setTimeout(() => {
  //         connectMetamask();
  //       }, 1000);
  //     });

  //     window.ethereum.on("networkChanged", async function (networkId) {
  //       if (networkId !== config.NETWORKVERSION) {
  //         toastAlert('error', "Please Switch BSC Network", 'excerciseerr');
  //         await window.ethereum.request({
  //           method: 'wallet_switchEthereumChain',
  //           params: [{ chainId: '0x61' }], // chainId must be in hexadecimal numbers
  //         });
  //         if (timerRef.current) {
  //           clearTimeout(timerRef.current);
  //         }
  //         timerRef.current = setTimeout(() => {
  //           connectMetamask();
  //         }, 1000);
  //       }
  //     });
  //   }
  // });

  async function connectfunction(connector) {
    console.log(connector, 'vvvvvvvvvvvvvvvvvvvvvvvvvvvvv')
    try{
  
      let check = isMobile();
  
      if (
        check &&
        !window.ethereum && connector &&
        connector.connector.id != "walletConnect"
      ) {
        await connectMetamaskMobile();
        return;
      }
        var resp = connect(connector)
        console.log(resp, 'vvvvvvvvvvvvvvvvvvvvvvvvvvvvv')
        // toastAlert('success', "Wallet connected 🦊 ", 'wallet');
        window.$('#exampleModal').modal('hide');
        
        // setTimeout(function(){
        //     window.location.reload(false)
        // },2000)
    }catch(err){
  console.log(err,'errrr')
    }
   
  
    
  }
  function isMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }
  
  const connectMetamaskMobile = () => {
    const dappUrl = window.location.href.split("//")[1].split("/")[0];
    // console.log('dappaaaaaaaaaaaaaaaaUrl: ', dappUrl);
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
    // console.log(metamaskAppDeepLink, "dapppppp...")
    window.open(metamaskAppDeepLink, "_self");
  };

  console.log(walletClient,'walletClientoo')
  useEffect(() => {
    setConnection();
  }, [walletClient]);


  return (
    <div class="modal fade primary_modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Connect Wallet</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul className="connectwallet_list">
              {/* <li onClick={connectMetamask}>
                <img src={require("../assets/images/metamask_icon.png")} alt="logo" className="img-fluid" />
                <span>Metamask</span>
              </li>
              <li onClick={connectTrustwallet}>
                <img src={require("../assets/images/walletconnect_icon.png")} alt="logo" className="img-fluid" />
                <span>Wallet Connect</span>
              </li> */}
               {connectors && connectors.length > 0 && connectors.map((connector) => (
                                        <>
                                           
                                           <a target="_blank" className="mb-3 d-block">
                                            {
                                                connector && connector.id == 'metaMask' ?

                                                    <li type="button" className="connect_wallet_button" 
                                                        key={connector.id}
                                                        onClick={()=>connectfunction({connector})}
                                                    >
                                                        <img
                                                            src={require("../assets/images/metamask_icon.png")}
                                                            height="28"
                                                            width="28"
                                                            className="img-fluid"


                                                        />{" "}
                                                        <span>MetaMask</span>
                                                        {/* <span>{connector.name}
                                                            {!connector.ready && ' (unsupported)'}
                                                            {isLoading &&
                                                                connector.id === pendingConnector?.id &&
                                                                ' (connecting)'}</span> */}
                                                    </li> : ""
                                            }
                                            </a>
                                            <a target="_blank" className="mb-3 d-block">
                                            {
                                                connector && connector.id == 'walletConnect' ?

                                                    <li type="button" className="connect_wallet_button"
                                                      
                                                        key={connector.id}
                                                        onClick={()=>connectfunction({connector})}
                                                    >
                                                        <img
                                                         src={require("../assets/images/walletconnect_icon.png")}
                                                            height="28"
                                                            width="28"
                                                            className="img-fluid"

                                                       
                                                        />{" "}
                                                         <span>Wallet Connect</span>
                                                        {/* <span>{connector.name}
                                                            {!connector.ready && ' (unsupported)'}
                                                            {isLoading &&
                                                                connector.id === pendingConnector?.id &&
                                                                ' (connecting)'}</span> */}
                                                    </li> :
                                                    ''



                                            }
                                            </a>
                                        </>

                                    ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Wallet_Popup;

